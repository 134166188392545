import { createSlice } from '@reduxjs/toolkit'
import { CLIENT } from '../utils/constants'

export const mainPageSlice = createSlice({
    name: 'mainPageSlice',
    initialState: {
        homeGlbSearch: "",
        userType: CLIENT,
        linkToRedirectTo: null,
        loginAndSignPopup: false,
        currentUserType: null,
    },
    reducers: {
        setHomeGlbSearch: (state, action) => {
            state.homeGlbSearch = action.payload
        },
        setUserType: (state, action) => {
            state.userType = action.payload
        },
        setLinkToRedirectTo: (state, action) => {
            state.linkToRedirectTo = action.payload
        },
        setLoginAndSignPopup: (state, action) => {
            state.loginAndSignPopup = action.payload
        },
        setCurrentUserType: (state, action) => {
            state.currentUserType = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setHomeGlbSearch, setUserType, setLinkToRedirectTo, setLoginAndSignPopup, setCurrentUserType } = mainPageSlice.actions

export default mainPageSlice.reducer