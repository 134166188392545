import React from "react";
import styles from "./HapePageRight.module.scss";

export default function HapePageRight({
  title,
  icon,
  hapeBodyTopTitle,
  HapeBodyBottomContent
}) {
  return (
    <div id={styles.hapePageRight}>
      {title&& <div className="hape-title">{title}</div>}
      <div className="hape-body">
        {icon && hapeBodyTopTitle && (
          <div className="hape-body-top">
            <div className="icon"><img src={icon} alt="Icon" /></div>
            <div className="hapeBodyTopTitle">{hapeBodyTopTitle}</div>
          </div>
        )}
        <div className="hape-body-bottom">
          <HapeBodyBottomContent />
        </div>
      </div>
    </div>
  );
}
