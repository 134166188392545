import * as THREE from 'three';
export const USE_FABRIC_CANVAS = 'USE_FABRIC_CANVAS';
export const handleFabricCanvas = (canvasid) => {
    const event = new CustomEvent(USE_FABRIC_CANVAS, {
        detail: {
            canvasid: canvasid
        }
    });
    document.dispatchEvent(event);
}

export const fabricCanvasHandler = ({ canvasname, selectedObject }) => {
    var texture = new THREE.CanvasTexture(document.getElementById(canvasname));
    texture.flipY = false;
    // texture.colorSpace = THREE.SRGBColorSpace;
    selectedObject.material.map = texture
    selectedObject.material.needsUpdate = true;
}