import { createSlice } from '@reduxjs/toolkit'

export const searchInputSlice = createSlice({
    name: 'searchInputSlice',
    initialState: {
        searchInput: ""
    },
    reducers: {
        setSearchInput: (state, action) => {
            state.searchInput = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSearchInput } = searchInputSlice.actions

export default searchInputSlice.reducer