const imageSlides = [
  {
    title: `
            Express Your Style:
            <span class='yellow'> Design</span>,
            <span class='yellow'> Customize</span>, and
            <span class='yellow'> Print</span> with Ease!
      `,
      sub_title: " Transform Your Vision into Wearable Art with Our User-Friendly Platform – Personalize Apparel to Reflect Your Unique Personality and Interests!",
      url: "/images/imagemiddleLogin.png",
    },
    {
      title: `
            Express Feelings and
            <span class='yellow'> Hipe</span> with new 
            <span class='yellow'> Stampers</span>
      `,
      sub_title: "Transform Your Vision into Wearable Art with Our User-Friendly Platform",
      url: "/images/image-1.png",
    },
    {
      title: `
            Express Your Style:
            <span class='yellow'> Wearable</span>,
            <span class='yellow'> Art </span>
            with Our User-Friendly Platform
      `,
      sub_title: "Transform Your Vision into Wearable Art with Our User-Friendly Platform ",
      url: "/images/image-3.png",
    },
    {
      
  
      title: `
      Fugit rerum iure impedit
      <span class='yellow'> consequuntur</span>,
      <span class='yellow'> laborum </span>
      fugit rerum iure impedit nulla id minus consequuntur cum, possimus 
      `,
      sub_title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, fugit rerum iure impedit nulla id minus consequuntur cum, possimus mollitia error laborum ut quod ab, laboriosam expedita reiciendis distinctio. Dolorem?",
      url: "/images/image-4.png",
    },
    {
      title: `
      <span class='yellow'> Possimus</span>,
      <span class='yellow'> Mollitia </span>
      fugit rerum iure impedit nulla id minus consequuntur cum, possimus 
      `,
    sub_title: " Transform Your Vision into Wearable Art with Our User-Friendly Platform – Personalize Apparel to Reflect Your Unique Personality and Interests!",
    url: "/images/imagemiddleLogin.png",
  },
  {
    title: `
            Express Your Style:
            <span class='yellow'> Design</span>,
            <span class='yellow'> Customize</span>, and
            <span class='yellow'> Print</span> with Ease!
      `,
    sub_title: " Transform Your Vision into Wearable Art with Our User-Friendly Platform – Personalize Apparel to Reflect Your Unique Personality and Interests!",
    url: "/images/imagemiddleLogin.png",
  },
];
export default imageSlides;  