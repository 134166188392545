export const cleanScene = (rendererRef, sceneRef,controls) => {
    try {
        rendererRef.dispose()
        // rendererRef.forceContextLoss()
        let cleanMaterial = material => {
            material.dispose()

            // dispose textures
            for (let key of Object.keys(material)) {
                let value = material[key]
                if (value && typeof value === 'object' && 'minFilter' in value) {
                    value.dispose()
                    cleanObject(value)
                    value = undefined
                } else {
                    value = undefined
                }
            }
        }
        let cleanGeometry = (geometry) => {
            geometry.dispose()
            for (let key of Object.keys(geometry)) {
                let value = geometry[key]
                if (value && typeof value === 'object') {
                    cleanObject(value)
                    value = undefined
                } else {
                    value = undefined
                }
            }
        }
        let cleanObject = (o) => {
            for (let key of Object.keys(o)) {
                let value = o[key]
                if (value && typeof value === 'object') {
                    value = undefined
                }
                else {
                    value = undefined
                }
            }
            return o = undefined
        }
        sceneRef.children.forEach((item) => {
            item.traverse((child) => {
                if (child.isMesh) {
                    cleanGeometry(child.geometry)
                    child.geometry = undefined
                    cleanMaterial(child.material)
                    child.material = undefined
                    cleanObject(child)
                    child = undefined
                }
                else {
                    cleanObject(child)
                    child = undefined
                }
            })
            sceneRef.remove(item);
        })
        cleanObject(sceneRef)
        sceneRef = undefined
        rendererRef = undefined
        controls = undefined
    }
    catch (e) {
        console.log("error occured deleting scene",e)
    }
}