import React from 'react'
import LHeaderText from '../LHeaderText/LHeaderText'
import Feature from '../feature/Feature'
import styles from "./FeatureDisplay.module.scss"

export const FeatureDisplayContext = React.createContext()
export default function FeatureDisplay({ color, reverse, headerText, features, background, hasDivider }) {
    return (
        <div style={{ background: background }} id={styles.FeatureDisplay}>
            <FeatureDisplayContext.Provider value={{ color, reverse, features }}>
                <LHeaderText text={headerText} />
                <Feature />
                <Divider hasDivider={hasDivider} />
            </FeatureDisplayContext.Provider>
        </div>
    )
}
export function Divider({ hasDivider }) {
    return <div id={styles.divider}>
        {hasDivider && <div className="divider-line"></div>}
    </div>
}

