import { gql } from "@apollo/client";

export const LIST_OF_TEXTURES = (search = null) => {
    return gql`
query LIST_OF_TEXTURES{
  users_textures${search && `(where: { texture_name: { _ilike: "%${search}%" } })`}{
    associated_glb
    created_at
    file_key
    file_url
    id
    rarity
    users_glb {
      id
    }
    texture_name
}
}
`
};