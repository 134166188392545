import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { GET_USER_INFO } from "../graphql/userInfo.query";
import CircularProgressComponent from "../components/circularProgress/CircularProgress.component";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CLIENT, ENDUSER } from "../utils/constants";
import { appRoutes } from "../utils/appRoutes";
import { useDispatch } from "react-redux";
import { setCurrentUserType } from "../redux/mainPageSlice";

export default function GaurdClientAndEndUser(props) {
  const { loading, data, error } = useQuery(GET_USER_INFO, { fetchPolicy: "no-cache" });
  const [next, setNext] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      if (data.users[0].user_type !== CLIENT && data.users[0].user_type !== ENDUSER) {
        navigate(appRoutes.login);
      } else {
        dispatch(setCurrentUserType(data.users[0].user_type));
        setNext(true);
      }
    }
    if (error) {
      navigate(appRoutes.login);
    }
  }, [data, error, navigate]);

  return (
    <>
      {loading && <CircularProgressComponent />}
      {next && <>{props.children}</>}
    </>
  );
}
