import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import React, { useRef } from "react";
import styles from "./Products.module.scss";
// import { filtersData } from './data'
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { LIST_OF_GLBS } from "../../../graphql/listOfGlbs.query";
import { useNavigate } from "react-router-dom";
import { LIST_OF_CATEGORY } from "../../../graphql/listOfCategory.query";
import EmptyFilter from "../../../components/emptyFilter/emptyFilter";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { apiRoutes, authetictedAxios } from "../../../utils/api";
import { IS_OBJECT_LIKED } from "../../../graphql/isObjectLiked.query";
import AutheticatedGraphql from "../../../providers/AutheticatedGraphql";
import { NUMBER_OF_LIKES_QUERY } from "../../../graphql/numberOfLikes.subscription";
import UnAutheticatedGraphql from "../../../providers/UnAutheticatedGraphql";
import { setLoginAndSignPopup } from "../../../redux/mainPageSlice";

export default function Products() {
  const [showDesigns, setShowDesigns] = useState("");
  const setHomeGlbSearch = useSelector((state) => state.mainPageSliceReducer.homeGlbSearch);
  const { data, loading } = useQuery(LIST_OF_GLBS(setHomeGlbSearch, showDesigns), { fetchPolicy: "no-cache" });
  const { data: listOfCategory } = useQuery(LIST_OF_CATEGORY);
  const handleReset = () => {
    setShowDesigns("");
  };
  return (
    <div className={styles.products}>
      <div className="header">
        <Typography variant="h1" className="title">
          Community Designs
        </Typography>
        <div className="btns">
          {listOfCategory &&
            listOfCategory.glb_categories.map((btn) => {
              return (
                <div className={`btn ${showDesigns === btn.id && "active"}`} key={btn.id} onClick={() => setShowDesigns(btn.id)}>
                  {btn.title}
                </div>
              );
            })}
        </div>
      </div>
      <div className="products">
        {loading && (
          <div className="loading">
            <LinearProgress  />
          </div>
        )}
        {data &&
          data.users_glbs.map((design, id) => {
            return (
              <AutheticatedGraphql key={id}>
                <SingleProduct design={design} id={id} />
              </AutheticatedGraphql>
            );
          })}
        {data && data.users_glbs.length === 0 && (
          <>
            <EmptyFilter
              setSearchValue={handleReset}
              title="No Matching Configuration Found"
              desc=" We're sorry, but the configurator search and filter you performed did not return
        any results. We recommend reviewing the filters you're using or
        resetting them altogether. To reset your glb search filter, please click
        the button below"
            />
          </>
        )}
      </div>
    </div>
  );
}

export function SingleProduct({ design, id }) {
  const { mutateAsync } = useMutation(async () => {
    const data = {
      likedObject: design.id,
    };
    const response = await authetictedAxios().post(apiRoutes.likes, data);
    await refetch();
    return response.data;
  });

  const { loading, data, refetch } = useQuery(IS_OBJECT_LIKED, {
    variables: {
      likedObject: design.id,
    },
  });

  const dispatch = useDispatch();
  const handleLikeClick = async () => {
    if (!localStorage.getItem("token")) {
      dispatch(setLoginAndSignPopup({ message: "To like this product please if you don't have an account login or if you have an account sign up" }));
    } else {
      return await mutateAsync();
    }
  };

  const likeBtn = useRef(null);
  const profileRef = useRef(null);

  const navigate = useNavigate();
  const handleRedirect = (e) => {
    const oneOfChildsClicked = (node) => {
      if (node.childNodes.length === 0) {
        return false;
      }
      for (let i = 0; i < node.childNodes.length; i++) {
        if (node.childNodes[i].isSameNode(e.target)) {
          return true;
        }
        return oneOfChildsClicked(node.childNodes[i]);
      }
    };
    if (oneOfChildsClicked(likeBtn.current) || oneOfChildsClicked(profileRef.current)) return true;
    navigate(`/${design.id}`);
  };
  return (
    <div style={{ backgroundImage: `url("${design.placeholder_image_url}")` }} className={`${styles.card}`} onClick={(e) => handleRedirect(e)} key={id}>
      <div className="top">
        <div ref={profileRef}>
          <UnAutheticatedGraphql>
            <NumberOfLikes design={design} />
          </UnAutheticatedGraphql>
        </div>
        <div className="right" onClick={handleLikeClick} ref={likeBtn}>
          {data && data.user_likes.length !== 0 && (
            <div className="icon">
              <img src="/images/favorite.svg" alt="icon" className="icon_img" />
            </div>
          )}
          {data && data.user_likes.length === 0 && (
            <div className="icon">
              <img src="/images/favoriteStroke.svg" alt="icon" className="icon_img" />
            </div>
          )}
          {loading && <CircularProgress sx={{ color: "#FF7163 !important" }} />}
        </div>
      </div>
      <div className="bottom">
        <div className="name">{design.name}</div>
        <div className="icon">
          <img src="/images/edit-2.svg" alt="icon" className="icon_img" />
        </div>
      </div>
    </div>
  );
}
function NumberOfLikes({ design }) {
  const { data, loading } = useQuery(NUMBER_OF_LIKES_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      likedItem: design.id,
    },
  });
  return (
    <div className={styles.left}>
      <img src="/images/profileImgUser.png" alt="profile" className="profileImg" />
      {data && <div className="number">{data.user_likes_aggregate.aggregate.count}</div>}
      {loading && (
        <div className="number" style={{ display: "grid", placeItems: "center" }}>
          ***
        </div>
      )}
    </div>
  );
}
