import React, { useRef, useState, useEffect } from "react";
import styles from "./TopNav.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSearchInput } from "./topNav.slice";
import { appRoutes } from "../../utils/appRoutes";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { CLIENT } from "../../utils/constants";
import { GET_USER_PROFILE } from "../../graphql/userProfileInfo.query";
import { useQuery } from "@apollo/client";

export default function TopNav(props) {
  const { setNavbarTitle, navbarTitle } = props;

  const [active, setActive] = useState(false);
  const handleCloseSideNav = () => {
    setNavbarTitle(false);
  };
  const handleOpenSideNav = () => {
    setNavbarTitle(true);
  };
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(setSearchInput(e.target.value));
  };
  const searchInputValue = useSelector((state) => state.headerReducers.searchInput);
  const selectValue = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(selectValue.current.value);
  };

  const [selectState, setselectState] = useState("");

  const handleActiveBtnEdit = (e) => {
    navigate(appRoutes.userProfile);
  };
  const redirectToPublicProfile = () => {
    navigate(appRoutes.publicProfileFunc(data.users[0]?.id));
  };
  const { pathname } = location;

  useEffect(() => {
    if (pathname === appRoutes.userProfile) {
      setActive(true);
    }
  }, [pathname]);

  const { data } = useQuery(GET_USER_PROFILE, { fetchPolicy: "no-cache" });
  {console.log("tehd ara",data)}
  const userType = useSelector((state) => state.mainPageSliceReducer.currentUserType);
  return (
    <div className={styles.topNav}>
      <div className="navBox">
        <div className="arrow_box">
          {navbarTitle && <img src="/images/arrow-left.svg" alt="Arrow Left" className="arrow" onClick={handleCloseSideNav} />}
          {!navbarTitle && <img src="/images/arrow-right.svg" alt="Arrow right" className="arrow" onClick={handleOpenSideNav} />}
        </div>
        <form className="search-filter" onSubmit={handleSubmit}>
          <input type="text" placeholder="Search For Asset" className="search" value={searchInputValue} onChange={handleChange} />
          <select name="asset" id="asset" className="filter" ref={selectValue} value={selectState || pathname} required onChange={(e) => setselectState(e.target.value)}>
            <option value="">Filter all assets</option>
            {userType === CLIENT ? (
              <>
                <option value={appRoutes.manageGlb}>Glbs</option>
                <option value={appRoutes.manageTextures}>Textures</option>
                <option value={appRoutes.manageGlbHdrs}>Hdris</option>
              </>
            ) : (
              <>
                <option value={appRoutes.endUserGlbs}>Mods</option>
              </>
            )}
          </select>
        </form>
        <div className="profile">
          <div className="button view" onClick={redirectToPublicProfile}>
            View Public Profile
          </div>
          <div className={active ? "button active" : "button"} id="btnEditProfile" onClick={handleActiveBtnEdit}>
            Edit Profile
          </div>
          <div className="d-flex">
            <div className="name">{data?.users_general_information[0]?.username}</div>
            <div className="imgprofile">
              <div style={{ backgroundImage: `url("${data?.users_general_information[0]?.user_profile_picture}")` }} className="picture" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
