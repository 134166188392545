import React from "react";
import styles from "./selectCardTexture.module.scss";
const check = "./images/check.svg";

export default function SelectCardTexture({ setGetValue}) {
  const valuesToSelected = [
    { logo: "./images/commonLogo.svg", value: "Common", check },
    { logo: "./images/rareLogo.svg", value: "Rare", check },
    { logo: "./images/myticalLogo.svg", value: "Mytical", check },
    { logo: "./images/legendaryBlacklogo.svg", value: "Legendary", check },
  ];

  const getSelectedValue = (logo, value) => {
    setGetValue({ logo, value });
  };

  return (
    <div id={styles.selectCardTexture}>
      {valuesToSelected.map((option) => (
        <div
          key={option.logo}
          className="option"
          onClick={() => getSelectedValue(option.logo, option.value)}
        >
          <img className="logo" src={option.logo} alt="logo" />
          <div className="box">
            <div type="text" className="value">
              {option.value}
            </div>
            <img src={check} alt="checkBox" className="checkBox" />
          </div>
        </div>
      ))}
    </div>
  );
}
