import { Button } from "@mui/material";
import React from "react";
import styles from "./updateTexturePopUp.module.scss";
import { useState } from "react";
import { TextareaAutosize } from "@mui/base";

function UpdateTexturePopUp(props) {
  const { content, setAnchorEl, handlePrompt } = props;
  const [text, setText] = useState(content);

  const handleChangeDetails = (e) => {
    setText(e.target.value);
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    handlePrompt(text);
    setAnchorEl(null);
  };

  return (
    <form className={styles.updateTexturePopUp} onSubmit={handleSubmitUpdate}>
      <div className="header">
        <div className="box" onClick={() => setAnchorEl(false)}>
          <img src="/images/closeUpdate.svg" alt="Close" className="closeIcon" />
        </div>
      </div>
      <TextareaAutosize className="content" value={text} onChange={handleChangeDetails} />
      <div className="actions">
        <Button variant="contained" size="small" color="primary" type="submit">
          Update Generation
        </Button>
      </div>
    </form>
  );
}

export default UpdateTexturePopUp;
