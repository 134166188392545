import { gql } from "@apollo/client";

export const LIST_OF_MODS = (search = "") => {
    return gql`
query LIST_OF_MODS{
  user_moded_glb${(search) && `(where:{ ${search && `name: { _ilike: "%${search}%" }`} })`}{
    file_key
    glb_file_url
    name
    id
    placeholder_image_url
    users_glb {
      SKU
      id
    }
}
}
`
};