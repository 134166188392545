import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  nameEdit: "",
};

const inputFieldSlices = createSlice({
  name: "inputFields",
  initialState,
  reducers: {
    setNameEdit(state, action) {
      state.nameEdit = action.payload;
    },
  },
});

export const { setNameEdit } = inputFieldSlices.actions;
const inputFieldSlicesReducers = inputFieldSlices.reducer;
export default inputFieldSlicesReducers;
