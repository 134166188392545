import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeletePopup from "../../components/deletePopup/deletePopup";
import EmptyFilter from "../../components/emptyFilter/emptyFilter";
import PreviewPopup from "../../components/previewPopup/previewPopup";
import styles from "./ManageGlb.module.scss";
import { useQuery } from "@apollo/client";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { Link } from "react-router-dom"
// import { useGlbDelete } from "../../components/deletePopup/useDeleteGlb.hook";
import { LIST_OF_TEXTURES } from "../../graphql/listOfTextures.mutation";
import { useTextureDelete } from "../../components/deletePopup/useDeleteTexture.hook";
import { useDispatch, useSelector } from "react-redux";
import { setSearchInput } from "../../components/topNav/topNav.slice";

export default function ManageTexture() {
  const searchInputValue = useSelector((state) => state.headerReducers.searchInput);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [showPreviewPopUp, setShowPreviewPopUp] = useState({ permition: false, image: "" });
  const [searchValue, setSearchValue] = useState(searchInputValue);
  const [deleteId, setDeleteId] = useState(null)

  const dispatch = useDispatch();
  const handleReset = () => {
    setSearchValue("");
    dispatch(setSearchInput(""));
  };
  useEffect(() => setSearchValue(searchInputValue), [searchInputValue]);
  const { data: listOfTextures, refetch } = useQuery(LIST_OF_TEXTURES(searchValue), { fetchPolicy: "no-cache" })

  const handleDeletePopup = (id) => {
    setShowDeletePopUp(true);
    setDeleteId(id)
  }
  const handlePreviewPopup = (id, image) => {
    setShowPreviewPopUp({ permition: true, image, });
  }

  const handleSeachFilter = (e) => {
    setSearchValue(e.target.value);
  }

  return (
    <DashboardLayout >
      <div className={styles.manageGlb}>
        <div className="upload">
          <div className="top_content">
            <div className="title">Manage Your Textures</div>
            <div className="sub_title">Manage textures for glbs</div>
          </div>
          <div className="uploaded_glb">
            <div className="uploaded_glb_top">
              <Typography variant="h5" className="label">
                Uploaded Textures
              </Typography>
              <div className="searchBox">
                <input type="text" className="search_input" placeholder="Search Texture" value={searchValue} onChange={(e) => handleSeachFilter(e)} />
                <img src="/images/search.svg" alt="search" className="search" />
              </div>
            </div>
            {listOfTextures && listOfTextures.users_textures.length === 0 && (
              <EmptyFilter
                setSearchValue={handleReset}
                title="No Matching Textures Found"
                desc="We're sorry, but the texture search and filter you performed did not return any results. We recommend reviewing the filters you're using or resetting them altogether. To reset your texture search filter, please click the button below"
              />
            )}
            {listOfTextures && listOfTextures.users_textures.length !== 0 && (
              <div className="uploaded_glb_body">
                <div className="title_box">
                  <Typography variant="body2" className="child">
                    #
                  </Typography>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      Texture
                    </Typography>
                  </div>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      Associated Glb
                    </Typography>
                  </div>
                  <div></div>
                  <Typography variant="body2" className="child">
                    Preview Texture
                  </Typography>
                  <Typography variant="body2" className="child">
                    Edit Texture info
                  </Typography>
                  <Typography variant="body2" className="child">
                    Delete Texture
                  </Typography>
                </div>
                <div className="content_box">
                  {showDeletePopUp && (
                    <DeletePopup
                      showDeletePopUp={showDeletePopUp}
                      setShowDeletePopUp={setShowDeletePopUp}
                      deleteId={deleteId}
                      refetch={refetch}
                      useGlbDelete={useTextureDelete}
                      title="Are You Sure You Want To Delete This Texture?"
                      body="Please confirm that you want to delete this texture permanently from the system. This action cannot be undone, and any associated data and metadata and descriptions, will be deleted along with the texture."
                      deteleBtn="Delete Texture"
                    />
                  )}
                  {showPreviewPopUp && <PreviewPopup showPreviewPopUp={showPreviewPopUp} setShowPreviewPopUp={setShowPreviewPopUp} texture />}
                  {listOfTextures &&
                    listOfTextures.users_textures.map((item, id) => {
                      return (
                        <div key={id} className="row">
                          <Typography variant="body2" className="child">
                            {id + 1}
                          </Typography>
                          <div className="glb">
                            <img src={item.file_url} alt="imageLogo" className="image" />
                            <Typography variant="body2" className="child">
                              {item.texture_name}
                            </Typography>
                          </div>
                          <Link to={`/edit-glb/${item.users_glb.id}`}>
                            <Button variant="contained" sx={{ opacity: 0.3 }}>
                              View Associated Glb
                            </Button>
                          </Link>
                          <div></div>
                          <Button variant="outlined" className="preview child" onClick={() => handlePreviewPopup(id, item.file_url)}>
                            Preview Texture
                          </Button>
                          <Link to={`/edit-textures/${item.id}`}>
                            <Button variant="outlined" className="edit child">
                              Edit Texture Info
                            </Button>
                          </Link>
                          <Button variant="outlined" className="delete child" onClick={() => handleDeletePopup(item.id)}>
                            Delete Texture
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
