import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useFileUpload } from '../../hooks/UseFileUpload';
import { DELETE_HDRI } from '../../graphql/deletehdri.mutation';
import { SINGLE_HDRI_INFO } from '../../graphql/singlehdriInfo.query';

export const useHdriDelete = (key) => {
    const [deleteHasura, { data, loading, error }] = useMutation(DELETE_HDRI)
    const [hdriInformation, { data: hdriData, loading: hdriLoading, error: hdriError }] = useLazyQuery(SINGLE_HDRI_INFO)
    const { loading: deleteImgLoading, error: deleteImgError, data: deleteImgData, handleFileDelete: deleteImg } = useFileUpload(true)
    const [hookError, setHookError] = useState(null)

    const handleDelete = async () => {
        try {
            setHookError(null)
            const hdriInfo = await hdriInformation({ variables: { id: key } })
            const placeholdereUrl = await deleteImg(hdriInfo.data.users_hdri_by_pk.file_key)
            if (placeholdereUrl) {
                await deleteHasura({ variables: { id: key } })
            }
        } catch (error) {
            console.log(error)
            setHookError("an error occured when deleting the hdri file")
        }
    }

    const loadingState = loading || hdriLoading || deleteImgLoading
    const errorState = error || hdriError || deleteImgError || hookError
    const dataState = data && hdriData && deleteImgData

    return { loadingState, errorState, dataState, handleDelete }
}