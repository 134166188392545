import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'
import { loadCdn } from '../../utils/fileFetch';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { cleanScene } from '../../pages/Home/components/configurator/cleanup/cleanScene';

function HDRIViewer({ hdr }) {
    const canvasRef = useRef()

    useEffect(() => {
        // Get the canvas element
        const canvas = canvasRef.current

        // Create a new scene
        const scene = new THREE.Scene()

        const camera = new THREE.PerspectiveCamera(75, canvas.clientWidth / canvas.clientHeight, 0.1, 10000);

        // Set up camera position and rotation
        camera.position.set(0, 0, -10);
        // camera.lookAt(new THREE.Vector3(0,0,))
        // Set up depth of field
        const focusDistance = 20;
        const aperture = 0.0005;
        const blur = 0.0001;
        camera.userData.postprocessing = {
            focusDistance,
            aperture,
            blur,
        };

        // orbit controls
        const controls = new OrbitControls(camera, canvas);

        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.rotateSpeed = 0.5;
        controls.zoomSpeed = 1.0;
        controls.panSpeed = 0.8;
        controls.enableKeys = false;
        controls.maxDistance = 500;
        controls.minDistance = 0.1;
        controls.maxPolarAngle = Math.PI / 2.2;
        controls.minPolarAngle = 0;
        controls.target.set(0, 0, 0);

        // Create a new renderer
        const renderer = new THREE.WebGLRenderer({
            antialias: true,
            canvas: canvas,
            physicallyCorrectLights: true
        });

        renderer.setClearColor(0x2BBFE9, 1);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.setSize(canvas.clientWidth, canvas.clientHeight)

        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        const rgbeLoader = new RGBELoader();
        rgbeLoader.load(hdr, function (texture) {
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            scene.environment = envMap;
            scene.background = envMap;
            texture.dispose();
            pmremGenerator.dispose();
        });

        // Set the initial camera position
        camera.position.z = 5

        // Create a function to handle resizing the canvas
        function handleResize() {
            const { clientWidth, clientHeight } = canvas
            camera.aspect = clientWidth / clientHeight
            camera.updateProjectionMatrix()
            renderer.setSize(clientWidth, clientHeight)
        }

        // Create a metallic sphere
        const metallicMaterial = new THREE.MeshPhysicalMaterial({ color: 0xffffff, metalness: 1.0, roughness: 0.1 });
        const metallicSphere = new THREE.Mesh(new THREE.SphereGeometry(1, 32, 32), metallicMaterial);
        metallicSphere.position.x = -2;

        // Create a glass sphere
        const glassMaterial = new THREE.MeshPhysicalMaterial({ color: 0xffffff, metalness: 0, roughness: 0.2, transmission: 0.5, transparent: true });
        const glassSphere = new THREE.Mesh(new THREE.SphereGeometry(1, 32, 32), glassMaterial);
        glassSphere.position.x = 2;

        // Create a plastic sphere
        const plasticMaterial = new THREE.MeshPhysicalMaterial({ color: 0xffffff, metalness: 0, roughness: 0.5 });
        const plasticSphere = new THREE.Mesh(new THREE.SphereGeometry(1, 32, 32), plasticMaterial);

        // Add the spheres to the scene
        scene.add(metallicSphere);
        scene.add(glassSphere);
        scene.add(plasticSphere);

        // Add event listeners to handle resizing the canvas
        window.addEventListener('resize', handleResize)

        // Render the scene
        function render() {
            requestAnimationFrame(render)
            controls.update();
            renderer.render(scene, camera)
        }
        render()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [hdr])

    return <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
}

export default HDRIViewer
