import React, { useState } from 'react'
import { SketchPicker } from 'react-color';
import { handleColor } from '../configurator/events/setColor';

export default function ColorPicker() {
    const [color, setColor] = useState("#fff")
    const handleChangeComplete = (color, event) => {
        setColor(color.hex);
        handleColor(color.hex)
    };

  return (
      <div><SketchPicker color={color} onChangeComplete={handleChangeComplete} /></div>
  )
}
