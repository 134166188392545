import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useRef } from 'react';
import { INSERT_HDRI } from '../../../../graphql/insertHdri.mutation';
import { useFileUpload } from '../../../../hooks/UseFileUpload';
import { APP_CONTAINER_CLASS } from '../../../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from '../../../../utils/appRoutes';
import { SINGLE_HDRI_INFO } from '../../../../graphql/singlehdriInfo.query';
import { UPDATE_HDRI } from '../../../../graphql/updatehdri.mutation';

export const useHdriUpload = (edit) => {
    const [inserthdri, { data, loading }] = useMutation(INSERT_HDRI)
    const { loading: uploadImageLoading, data: uploadImageData, handleFileUplaod: uploadImage } = useFileUpload(true)
    const { loading: deleteImageLoading, data: deleteImageData, handleFileDelete: deleteImage } = useFileUpload(true)
    const { id } = useParams()
    const [hdriInformation, { data: glbData, loading: glbLoading }] = useLazyQuery(SINGLE_HDRI_INFO, { fetchPolicy: "no-cache" })
    const [updatehdri, { loading: updateGlbLoading, data: updateGlbData }] = useMutation(UPDATE_HDRI)
    const [hookError, setHookError] = useState(null)

    const navigate = useNavigate()
    const requestRef = useRef()

    const fileHandling = async (hdriImage, hdriInfo) => {
        const handleplaceholderImage = async () => {
            if (edit) {
                if (typeof hdriImage === "string") {
                    return {
                        key: hdriImage.split(process.env.REACT_APP_CLOUDFRONT_URL).pop(),
                        url: hdriImage
                    }
                }
                await deleteImage(hdriInfo.file_key)
                return await uploadImage(hdriImage, "hdri")
            }
            return await uploadImage(hdriImage, "hdri")
        }

        const hdriImageUrl = await handleplaceholderImage()
        return { hdriImageUrl }
    }
    const handleGlbUpload = async (data) => {
        try {
            if (requestRef.current) return null
            requestRef.current = true
            document.querySelector(APP_CONTAINER_CLASS).scrollTo({ top: 0, behavior: 'smooth' });
            setHookError(null)
            const { hdriName, associatedGlb, productRarity, hdri } = data
            const glbCurrentInfo = edit && await hdriInformation({ variables: { id } })
            const { hdriImageUrl } = await fileHandling(hdri, edit && glbCurrentInfo?.data?.users_hdri_by_pk)
            if (hdriImageUrl) {
                if (edit) {
                    await updatehdri({
                        variables: {
                            file_key: hdriImageUrl.key,
                            file_url: hdriImageUrl.url,
                            rarity: productRarity,
                            associated_glb: associatedGlb,
                            hdri_name: hdriName,
                            id
                        }
                    })
                }
                else {
                    await inserthdri({
                        variables: {
                            file_key: hdriImageUrl.key,
                            file_url: hdriImageUrl.url,
                            rarity: productRarity,
                            associated_glb: associatedGlb,
                            hdri_name: hdriName,
                        }
                    })
                }
                navigate(appRoutes.manageGlbHdrs)
                requestRef.current = false
            }
        } catch (error) {
            console.log(error)
            requestRef.current = false
            setHookError("an error occured when upload the hdri file")
        }
    }


    const handleLoadingMessage = () => {
        if (loading) {
            return "Creating hdri file"
        }
        if (uploadImageLoading) {
            return "uploading hdri image"
        }
        if (deleteImageLoading) {
            return "deleting old hdri image"
        }
        if (updateGlbLoading) {
            return "Updating hdri info"
        }
        if (glbLoading) {
            return "loading hdri info"
        }
    }

    const loadingState = handleLoadingMessage()
    const errorState = hookError
    const dataState = data && uploadImageData && deleteImageData && updateGlbData && glbData

    return { loadingState, errorState, dataState, handleGlbUpload }
}