import React, { useState, useLayoutEffect, useRef, useContext } from "react";
import styles from "./PageManIconPart.module.scss";
import IconComponent from "../IconComponent/IconComponent";
import Configurator from "../configurator/Configurator";
import { exportGlb } from "../configurator/events/exportGlb";
import { useParams, useSearchParams } from "react-router-dom";
import { SINGLE_GLB_INFO } from "../../../../graphql/singleGlbInfo.query";
import { useQuery } from "@apollo/client";
import CircularProgressComponent from "../../../../components/circularProgress/CircularProgress.component";
import NotFound from "../../../../components/404/404";
import { Button, CircularProgress, Popover, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setNameEdit } from "./fieldInput.slice";
import { homeContext } from "../../Home";

//getting images of icons
const rotate = "./images/3d_rotation.svg";
const addCicleOutline = "./images/add-circled-outline.svg";
const shirt = "./images/shirt.svg";
const brain = "./images/brain-cloud.svg";
const maximize = "./images/maximize-2.svg";
const save = "./images/save.svg";
const saveBlack = "./images/saveBlack.svg";

export default function PageManIconPart({ setRenderCustomize }) {
  const [activeIcon, setActiveIcon] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [saveGlbStateElement, setSaveGlbStateElemenet] = useState(null);
  const { setLoginAndSignPopup, setLoadingPopup } = useContext(homeContext)


  const dispatch = useDispatch();
  const nameEdit = useSelector((state) => state.inputFieldSlicesReducers.nameEdit);

  const handleSaveGlbOpenPopOver = (target) => {
    setSaveGlbStateElemenet(target);
  };

  const openSave = Boolean(saveGlbStateElement);
  const idClickedIconPopOver = openSave ? "shareCustomize" : undefined;

  const handleCloseSavePopOver = () => {
    setSaveGlbStateElemenet(null);
  };

  const IconComponentDataLeft = [
    { svg: rotate, id: "rotate3D" },
    { svg: shirt, id: "shirtCustomize" },
    { svg: addCicleOutline, id: "addCustomize" },
    { svg: brain, id: "colorPalette" },
  ];
  const IconComponentDataRight = [
    { svg: maximize, id: "maximizeCustomize", onClick: () => {} },
    {
      svg: saveBlack,
      id: "shareCustomize",
      onClick: (e) => {
        // exportGlb();
        if (nameFromUrl) {
          return exportGlb({
            name: nameFromUrl,
            edit: Boolean(nameFromUrl),
            id: modIdFromUrl,
            glbId: id,
          });
        }
        return handleSaveGlbOpenPopOver(e.currentTarget);
      },
    },
  ];
  const token = localStorage.getItem('token');
  const handleSubmitSaveGlb = (e) => {
    e.preventDefault();
    if(!token){
      setLoginAndSignPopup({
        message: "You don't have rights to access this feature Unless you logged in!"
      });
      setSaveGlbStateElemenet(null);
    }else{
      handleGlbExport();
    }
  };

  const showActiveIcon = (svg, id) => {
    setActiveIcon(svg);
    setRenderCustomize(id);
  };

  useLayoutEffect(() => {
    setIsReady(true);
  }, []);

  const container = useRef(null);
  const { id } = useParams();
  console.log("the page id",id)
  const { loading, data, error } = useQuery(SINGLE_GLB_INFO, { variables: { id } });
  const [searchParams] = useSearchParams();
  const glbFromUrl = searchParams.get("glbToUse");
  const nameFromUrl = searchParams.get("name");
  const modIdFromUrl = searchParams.get("modId");

  const handleGlbExport = () => {
    exportGlb({
      name: nameFromUrl || nameEdit,
      edit: Boolean(nameFromUrl),
      id: modIdFromUrl,
      glbId: id,
    });
  };

  return (
    <div id={styles.pageManIconPart} ref={container}>
      {loading && (
        <div className="glbLoading">
          <CircularProgress />
        </div>
      )}
      {data && <Configurator container={container.current} glb={glbFromUrl || data.users_glbs_by_pk.glb_file_url} />}
      {error && (
        <div className="glbError">
          <NotFound
            body="Oops! The GLB you're looking for isn't available. Please check the URL and try again. If you believe this is an error, please contact our support team for assistance. Thank you for your understanding."
            title="Unable to find GLb related to this Id"
          />
        </div>
      )}
      <div className="icons">
        <div className="iconBox">
          {IconComponentDataLeft.map((icon) => (
            <div className="SinglIcon" key={icon.svg} onClick={() => showActiveIcon(icon.svg, icon.id)}>
              <IconComponent svg={icon.svg} active={activeIcon === icon.svg ? "active" : ""} />
            </div>
          ))}
        </div>
        <div className="iconBox">
          {IconComponentDataRight.map((icon) => (
            <>
              <div className="SinglIcon" key={icon.svg} aria-describedby={idClickedIconPopOver} onClick={icon.onClick}>
                <IconComponent svg={icon.svg} active={activeIcon === icon.svg ? "active" : ""} />
              </div>
              {icon.id === "shareCustomize" && !nameFromUrl && (
                <Popover
                  id={idClickedIconPopOver}
                  open={openSave}
                  anchorEl={saveGlbStateElement}
                  onClose={handleCloseSavePopOver}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  about="popOverWithInput"
                >
                  <form className="formSave" onSubmit={handleSubmitSaveGlb} autoComplete="off">
                    <TextField id="nameEdit" name="name" placeholder="Enter The Name For This Edit" onChange={(e) => dispatch(setNameEdit(e.target.value))} value={nameEdit} about="popOpverInput" />
                    <Button type="submit" className="btnPOpOverSave" about="popOverBtn" variant="contained" color="primary">
                      <img src={save} alt="icon" className="imgIcon" />
                    </Button>
                  </form>
                </Popover>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="buttons">
        {/* <div className="create">
          <div className="contain"></div>
          <img src="./images/shopping-bag.svg" alt="bag" className="img" />
          <div className="letters"> Order One Product</div>
        </div>
        <div className="create">
          <div className="contain"></div>

          <img src="./images/blockchain.png" alt="blockchain" className="img" />
          <div className="letters"> Create a collection</div>
        </div> */}
      </div>
    </div>
  );
}
