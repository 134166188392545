import React from "react";
import styles from "./publicProfile.module.scss";
import MainNavBar from "../Home/components/MainNavBar/MainNavBar";
import { Alert, CircularProgress } from "@mui/material";
import EmptyFilter from "../../components/emptyFilter/emptyFilter";
import { SingleProduct } from "../listOfProducts/components/Products";
import useFetchUser from "./useFetchUserInfo";
import { useParams } from "react-router-dom";

export default function PublicProfile() {
  const {id} = useParams();
  const { loading, data, error } = useFetchUser(id);

  return (
    <div className={styles.publicProfile}>
      <MainNavBar />
      <div className="pageBody">
        {data && data.users_general_information?.length>0&&(
          <div className="single_item_data profile">
            <div style={{backgroundImage: `url("${data.users_general_information[0].user_profile_picture}")`}} className="profilePhoto"></div>
            <div className="details">
              <div className="top">
                <div className="name">{data.users_general_information[0].username}</div>
                <div className="location">{data.users_general_information[0].location}</div>
              </div>
              <div className="bio">{data.users_general_information[0].bio}</div>
            </div>
          </div>
        )}

        {loading && (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error.message}</Alert>}
        {data &&
          data.users_glbs.length > 0 &&
          data.users_glbs.map((design, id) => {
            return (
              <div className="single_item_data" key={id}>
                <SingleProduct design={design} id={id} />
              </div>
            );
          })}
      </div>
    </div>
  );
}
