import { gql } from "@apollo/client"

export const INSERT_GLB = gql`
mutation INSERT_GLB(
  $SKU:String!,
  $category:Int!,
  $file_key:String!,
  $name:String!
  $glb_file_url:String!
  $placeholder_image_url:String!
  $placeholder_key:String!
  $private:Boolean!
){
  insert_users_glbs_one(object:{
    SKU:$SKU,
    file_key:$file_key,
    name:$name,
    glb_file_url:$glb_file_url,
    placeholder_image_url:$placeholder_image_url,
    placeholder_key:$placeholder_key,
    private:$private,
    category:$category,
  }){
    id
  }
}`