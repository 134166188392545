import React from 'react'
import LHeader from './components/LHeader/LHeader'
import Hero from './components/hero/Hero'
import FeatureDisplay from './shared/featureDisplay/FeatureDisplay'
import { firstFeature, secondFeature } from './constants/featuresData'
import CreateSection from './components/createSection/CreateSection'
import Footer from './components/footer/Footer'

export default function LandingPage() {

  return (
    <>
      <LHeader />
      <Hero />
      <FeatureDisplay {...firstFeature} />
      <FeatureDisplay {...secondFeature} />
      <CreateSection />
      <Footer />
    </>
  )
}
