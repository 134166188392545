export const firstFeature = {
    color: "white",
    reverse: false,
    background: "#2E2E2E",
    headerText: "TOOLS FOR",
    hasDivider: false,
    features: [
        {
            text: "GAMES",
            description: `Monetise your game to an 
e-commerce and ownership experience for your community. Enable them to customise and receive Skinz both in game and in life.`,
            image: "/images/monitize.png",
        },
        {
            text: "INFLUENCERS",
            description: `Monetise your game to an 
e-commerce and ownership experience for your community. Enable them to customise and receive Skinz both in game and in life.`,
            image: "/images/monitize.png",
        },
        {
            text: "BRANDS",
            description: `Monetise your game to an 
e-commerce and ownership experience for your community. Enable them to customise and receive Skinz both in game and in life.`,
            image: "/images/monitize.png",
        },
        {
            text: "PRODUCTS",
            description: `Monetise your game to an 
e-commerce and ownership experience for your community. Enable them to customise and receive Skinz both in game and in life.`,
            image: "/images/monitize.png",
        },
    ],
};
export const secondFeature = {
    color: "black",
    background: "white",
    reverse: true,
    headerText: "CONNECT",
    hasDivider: true,
    features: [
        {
            text: "PHYSICAL PRODUCTS",
            description: `Monetise your game to an 
e- commerce and ownership experience
for your community.Enable them to customise and receive Skinz
both in game and in life.`,
            image: "/images/avatars.png",
        },
        {
            text: "DIGITAL PRODUCTS",
            description: `Monetise your game to an 
e- commerce and ownership experience
for your community.Enable them to customise and receive Skinz
both in game and in life.`,
            image: "/images/avatars.png",
        },
        {
            text: "EVENTS",
            description: `Monetise your game to an 
e- commerce and ownership experience
for your community.Enable them to customise and receive Skinz
both in game and in life.`,
            image: "/images/avatars.png",
        },
    ],
};
export const createImages = [
    "/images/image8.png", "/images/image9.png", "/images/iamge10.png"
]