import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    HttpLink,
    from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

export default function UnAutheticatedGraphql({ children }) {
    const link = from([
        new HttpLink({
            uri: `${process.env.REACT_APP_HASURA_URL}`,
            credentials: 'include',
        }),
    ]);
    const authLink = setContext((_, { headers }) => {
        return {
            headers: {
                ...headers
            },
        };
    });

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(link),
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}