import React, { useContext } from 'react'
import { FeatureDisplayContext } from '../featureDisplay/FeatureDisplay'
import styles from "./LHeaderText.module.scss"

export default function LHeaderText(props) {
    const { text } = props
    const SWContext = () => {
        if (props.simple) {
            return { color: "white", display: "flex", flexDirection: "row" }
        }
        return { color: value.color, display: "flex", flexDirection: value.reverse ? "row-reverse" : "row" }
    }
    const value = useContext(FeatureDisplayContext)
    return (
        <div style={SWContext()} id={styles.headerText}>{text}</div>
    )
}
