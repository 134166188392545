import React from "react";
import styles from "./Customize360Environment.module.scss";
import CustomizeStyler from '../CustomizeStyler/CustomizeStyler';
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { SINGLE_HDRI_TEXTURE_INFO } from "../../../../graphql/singleGlbHdrisInfo.query"
import UnAutheticatedGraphql from "../../../../providers/UnAutheticatedGraphql";
import StableDiffusion from "../../components/StableDiffusion/StableDiffusion"


export default function Customize360Environment() {
  const { id } = useParams()
  const { data } = useQuery(SINGLE_HDRI_TEXTURE_INFO, { variables: { id } })


  return (
    <UnAutheticatedGraphql>
      <div id={styles.customize360Environment}>
        {data && data.users_glbs_by_pk.users_hdris.length !== 0 && <CustomizeStyler Textures={data.users_glbs_by_pk.users_hdris.map((item, idx) => {
          return {
            imageSrc: item.file_url,
            number: idx + 1,
            name: item.hdri_name,
            showCardActions: false,
            showMaximize: false
          }
        })} hdr={true} />}
        {/* {data && data.users_glbs_by_pk.users_hdris.length === 0 && <NotFound body="The availability of this particular hdri may be limited at the moment. Please be patient as it is being prepared, and feel free to check back later." title="Can't find related hdri" />} */}
        {data && data.users_glbs_by_pk.users_hdris.length === 0 && <StableDiffusion />}
      </div>
    </UnAutheticatedGraphql>
  );
}
