import { GLTFExporter } from 'three/addons/exporters/GLTFExporter.js';
import { apiRoutes, authetictedAxios } from '../../../../../utils/api';
import { client } from '../../../../../index';
import { ADD_USER_GLB_MOD } from '../../../../../graphql/adduserGlbMod.mutation';
import { v4 as uuidv4 } from 'uuid';
import { EDIT_USER_GLB_MOD } from '../../../../../graphql/edituserGlbMod.mutation';
import { appRoutes } from '../../../../../utils/appRoutes';
export function exportSceneAsGLB(scene, filename = 'scene.gltf', renderer, setLoadingPopup, name) {
    // Create a new GLTFExporter instance
    console.log("the mods", name)
    const exporter = new GLTFExporter();
    // Export the scene as GLB
    async function save(blob, filename) {
        let file = new File([blob], filename, { type: "text/plain", lastModified: new Date() });

        // Now 'file' can be uploaded using a FormData object and an AJAX request, for example
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileType", 'glb');
        const result = await authetictedAxios().post(apiRoutes.fileUpload, formData);

        const canvas = renderer.domElement
        canvas.toBlob(async (blob) => {
            const imagefile = new File([blob], 'image.png', { type: 'image/png' });
            const formDataImage = new FormData();
            formDataImage.append("file", imagefile);
            formDataImage.append("fileType", 'texture');
            const imageResult = await authetictedAxios().post(apiRoutes.fileUpload, formDataImage);

            if (result && imageResult) {
                if (!name.edit) {
                    await client.mutate({
                        mutation: ADD_USER_GLB_MOD,
                        variables: {
                            fileKey: result.data.key,
                            glb_file_url: result.data.url,
                            moded_glb: name.glbId,
                            name: name.name,
                            placeholder_image_url: imageResult.data.url,
                            image_key:imageResult.data.key
                        }
                    })
                    setLoadingPopup(false)
                    window.location.href = appRoutes.endUserGlbs
                }
                else {
                    await client.mutate({
                        mutation: EDIT_USER_GLB_MOD,
                        variables: {
                            fileKey: result.data.key,
                            glb_file_url: result.data.url,
                            moded_glb: name.glbId,
                            name: name.name,
                            placeholder_image_url: imageResult.data.url,
                            image_key: imageResult.data.key,
                            id: parseInt(name.id)
                        }
                    })
                    setLoadingPopup(false)
                    window.location.href = appRoutes.endUserGlbs
                }
            }
        }, 'image/png');
        // URL.revokeObjectURL( url ); breaks Firefox...

    }

    function saveArrayBuffer(buffer, filename) {
        save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
    }
    function saveString(text, filename) {

        save(new Blob([text], { type: 'text/plain' }), filename);

    }

    exporter.parse(scene, (result) => {
        setLoadingPopup(true)
        if (result instanceof ArrayBuffer) {

            saveArrayBuffer(result, 'scene.glb');

        } else {

            const output = JSON.stringify(result, null, 2);
            saveString(output, 'scene.gltf');

        }
    }, { binary: false, onlyVisible: true, maxTextureSize: 4096 });
}