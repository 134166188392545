import { Typography } from "@mui/material";
import React from "react";
import styles from "./LoginSignupPages.module.scss";
import ImageSliders from "./components/ImageSliders/ImageSliders";
import { useLocation } from "react-router-dom";
import SignupForm from './components/Forms/SignupForm'
import LoginFrom from "./components/Forms/LoginFrom";


export default function LoginSignupPages() {
  const login_signupLocation = useLocation();

  return (
    <div id={styles.loginSignupPages}>
      <div className="form_part">
        <div className="logoBox"><img src="./images/logoBlack.svg" alt="logo" /></div>
        <div className="body_box">
          <div className="body">
            <Typography variant="h1" className="title">Get Started now</Typography>
            <Typography variant="body2" color="secondary">Enter your credentials to access your account</Typography>
            <Typography variant="body2" className="continue" >Continue with</Typography>
            <div className="medias">
                <a href="google.com" className="box">
                  <img src="./images/google.svg" alt="google" className="image" />
                </a>
                <a href="google.com" className="box">
                  <img src="./images/apple.svg" alt="apple" className="image" />
                </a>
                <a href="gitpod.com" className="box">
                  <img src="./images/gitpod.svg" alt="gitpod" className="image" />
                </a>
                <a href="facebook.com" className="box">
                  <img src="./images/facebook.svg" alt="facebook" className="image" />
                </a>
                <a href="twitter.com" className="box">
                  <img src="./images/twitter.svg" alt="twitter" className="image" />
                </a>
            </div>
            <div className="lineBorder">
              <div className="line"></div>
              <div className="word">or</div>
              <div className="line"></div>
            </div>
            {login_signupLocation.pathname ==="/signup"  && <SignupForm />}
            {login_signupLocation.pathname ==="/login"  && <LoginFrom />}
          </div>
        </div>
      </div>
      <div className="images_part">
        <ImageSliders />
      </div>
    </div>
  );
}
