import React, { useState } from "react";
import FormUpload from "./components/FormUpload/FormUpload";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import FormLayout from "../../components/dashboardLayout/FormLayout.layout";

export default function UploadHDRIPage(props) {
  const [placeholderPreview, setPlaceholderPreview] = useState(null);

  return (
    <DashboardLayout>
      <FormLayout
        title="Upload Your HDRI"
        subTitle="Submit Your HDRI Name, File, Rarity, and associated glb"
        previewHdri={placeholderPreview}
        {...props}
      >
        <FormUpload
          setPlaceholderPreview={setPlaceholderPreview}
          hdrPreview={placeholderPreview}
        />
      </FormLayout>
    </DashboardLayout>
  );
}
