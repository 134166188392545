import { gql } from "@apollo/client";

export const SINGLE_GLB_TEXTURE_INFO = gql`
query SINGLE_GLB_TEXTURE_INFO($id: uuid!) {
  users_glbs_by_pk(id: $id) {
    users_textures {
      file_url
      texture_name
    }
  }
}

`