import React, { useState } from "react";
import SideNav from "../sideNav/SideNav";
import TopNav from "../topNav/TopNav";
import styles from "./dashboardLayout.module.scss";

export default function DashboardLayout({ children }) {
  const [navbarTitle, setNavbarTitle] = useState(true);
  return (
    <div className={styles.dashboardLayout}>
      <SideNav navbarTitle={navbarTitle} />
      <div className="body_glb">
        <TopNav setNavbarTitle={setNavbarTitle} navbarTitle={navbarTitle} />
        <div className="upload_preview">{children}</div>
      </div>
    </div>
  );
}
