import React, { useState } from "react";
import FormUpload from "./components/FormUpload/FormUpload";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import FormLayout from "../../components/dashboardLayout/FormLayout.layout";


export default function UploadGlb(props) {
  const [glbPreview, setGlbPreview] = useState(null);
  const [placeholderPreview, setPlaceholderPreview] = useState(null);

  return (
    <DashboardLayout>
      <FormLayout
        title="Upload Your Glb"
        subTitle="Submit Your 3D Model: Name, File, Rarity, and SKU"
        glbPreview={glbPreview}
        imagePreview={placeholderPreview}
        imagePreviewTitle="Glb Placeholder Preview"
        {...props}
      >
        <FormUpload
          setGlbPreview={setGlbPreview}
          setPlaceholderPreview={setPlaceholderPreview}
        />
      </FormLayout>
    </DashboardLayout>
  );
}
