import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularProgressComponent(props) {
    const handleOnTop = () => {
        if (props.onTop) {
            return {
                position: "fixed",
                top: "0",
                left: "0",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: "100900000",
            }
        }
    }
    return (
        <div style={{
            display: "grid",
            placeItems: "center",
            background:"white",
            minWidth: "100vw",
            minHeight: "100vh",
            ...handleOnTop()
        }}>
            <CircularProgress />
        </div>
    )
}
