import React from "react";
import styles from "./BottomPageFonts.module.scss";

export default function BottomPageFonts() {
  return (
    <div id={styles.bottomPageFonts}>
      <div className="fonts">HAPE #4095</div>
    </div>
  );
}
