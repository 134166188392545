import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useFileUpload } from '../../hooks/UseFileUpload';
import { SINGLE_GLB_INFO } from '../../graphql/singleGlbInfo.query';
import { DELETE_GLB } from '../../graphql/deleteGlb.mutation';

export const useGlbDelete = (key) => {
    const [deleteGlbHasura, { data, loading, error }] = useMutation(DELETE_GLB)
    const [glbInformation, { data: glbData, loading: glbLoading, error: glbError }] = useLazyQuery(SINGLE_GLB_INFO)
    const { loading: deleteGlbLoading, error: deleteGlbError, data: deleteGlbData, handleFileDelete: deleteGlb } = useFileUpload(true)
    const { loading: deleteImgLoading, error: deleteImgError, data: deleteImgData, handleFileDelete: deleteImg } = useFileUpload(true)
    const [hookError, setHookError] = useState(null)

    const handleDeleteGlb = async () => {
        try {
            setHookError(null)
            const glbInfo = await glbInformation({ variables: { id: key } })
            const glbFileUrl = await deleteGlb(glbInfo.data.users_glbs_by_pk.file_key)
            const placeholdereUrl = await deleteImg(glbInfo.data.users_glbs_by_pk.placeholder_key)
            if (glbFileUrl && placeholdereUrl) {
                await deleteGlbHasura({ variables: { id: key } })
            }
        } catch (error) {
            console.log(error)
            setHookError("an error occured when upload the glb file")
        }
    }

    const loadingState = loading || glbLoading || deleteGlbLoading || deleteImgLoading
    const errorState = error || glbError || deleteGlbError || deleteImgError || hookError
    const dataState = data && glbData && deleteGlbData && deleteImgData

    return { loadingState, errorState, dataState, handleDelete: handleDeleteGlb }
}