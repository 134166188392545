import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
query GET_USER_PROFILE {
  users{
        first_name
        last_name
        id
  }
  users_general_information {
    bio
    industry
    location
    profile_key
    id
    user_profile_picture
    username
    user {
      first_name
      last_name
      id
    }
  }
}
`