import React from 'react'
import styles from "./CreateSection.module.scss"
import { Divider } from '../../shared/featureDisplay/FeatureDisplay'
import LHeaderText from '../../shared/LHeaderText/LHeaderText'
import { createImages } from '../../constants/featuresData'

export default function CreateSection() {
    return (
        <div id={styles.CreateSection}>
            <div className="lp"><LHeaderText text="CREATE" simple={true} /></div>
            <Divider hasDivider={true} />
            <div className="inBtwnImage" style={{ backgroundImage: `url("/images/inBtwnImg.png")` }}></div>
            <div className="lp">
                <div className="flex">
                    <div className="si-text">PRODUCT</div>
                    <div className="si-text">STORE</div>
                    <div className="si-text">ACCOUNT</div>
                </div>
                <div className="desc-text">Monetise your game to an e-commerce and ownership experience
                    for your community. Enable them to customise and receive Skinz
                    both in game and in life.</div>
            </div>
            <div className="product-pictures">
                {createImages.map((image, index) => {
                    return <PicturesCarousels key={index} image_url={image} />
                })}
            </div>
        </div >
    )
}


export const PicturesCarousels = ({ image_url }) => {
    return (
        <div className="single-image" style={{ backgroundImage: `url("${image_url}")` }}></div>
    )
}