import React from 'react'
import styles from "./Hero.module.scss"

export default function Hero() {
    return (
        <div id={styles.hero} style={{
            backgroundImage: `url("./images/back_hero.png")`
        }}>
            <div></div>
            <div className="hero-text">E-COMMERCE FOR THE OWNERSHIP ECONOMY</div>
            <div className="cta">
                <button className="btn cta-btn">CONFIGURE NOW</button>
            </div>
        </div>
    )
}
