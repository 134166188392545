import { useState, useEffect } from "react";
import { apiRoutes, unAuthetticatedAxios } from "../../utils/api";

const useFetchUser = (id) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const handleFetchData = async () => {
    try {
      setLoading(true);
      setData(null);
      setError(null);
      const result = await unAuthetticatedAxios.post(
        apiRoutes.userPublicProfile,
        {
          id: id,
        }
      );
      if (result) {
        setLoading(false);
        setData(result.data);
      }
    } catch (error) {
      setError(error.response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return { loading, data, error };
};

export default useFetchUser;
