import React from "react";
import styles from "./CustomizeTexture.module.scss";
import CustomizeStyler from '../CustomizeStyler/CustomizeStyler';
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { SINGLE_GLB_TEXTURE_INFO } from "../../../../graphql/singleGlbTextures.query"
import UnAutheticatedGraphql from "../../../../providers/UnAutheticatedGraphql";
import StableDiffusion from "../../components/StableDiffusion/StableDiffusion"

export default function CustomizeTexture() {
  const { id } = useParams()
  const { data } = useQuery(SINGLE_GLB_TEXTURE_INFO, { variables: { id } })

  return (
    <UnAutheticatedGraphql>
      <div id={styles.customizeTexture}>
        {data && data.users_glbs_by_pk.users_textures.length !== 0 && <CustomizeStyler Textures={data.users_glbs_by_pk.users_textures.map((item, idx) => {
          return {
            imageSrc: item.file_url,
            number: idx + 1,
            name: item.texture_name,
            showCardActions: false,
            showMaximize: false
          }
        })} textureImage={true} />}
        {data && data.users_glbs_by_pk.users_textures.length === 0 && <StableDiffusion />}
      </div>
    </UnAutheticatedGraphql>
  );
}
