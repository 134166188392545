import React, { useState } from 'react';
import styles from "./form.module.scss";
import { Button, Typography, TextField, InputAdornment, IconButton, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import * as Yup from "yup"
import { Formik, Form, Field } from 'formik';
import { useHandleSignUp } from './useHandleSignUp.hook';
import { users } from '../../../../utils/api';
import { useSelector } from 'react-redux';

const visibilityIcon = '/images/visibility.svg';
const visibilityOffIcon = '/images/visibilityoff.svg'

export default function SignupForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const navigate = useNavigate();

  // signup backend
  const { loading, error, data, handleSignup } = useHandleSignUp()
  // handle formik 
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Please enter a valid email").required("The email is required"),
    password: Yup.string().required("Password is required").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    comfirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Password must match").required("Comfirm password is required").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    acceptedTerms: Yup.boolean().oneOf([true], 'You must agree to the terms'),
  })

  const initialValues = { firstName: "", lastName: "", email: "", password: "", comfirmPassword: "", acceptedTerms: false }

  const userType = useSelector((state) => state.mainPageSliceReducer.userType);
  const onSubmit = async (values) => {
    await handleSignup({ ...values, googleSignUp: false, user_type: userType });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handlePush = () => {
    navigate('/login');
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        (formik) => {
          return (
            <Form className={styles.form} >
              {loading && <Alert severity="info">Loading...</Alert>}
              {data && <Alert severity="success">Sign-up successful! Verify your email.</Alert>}
              {error && <Alert severity='error'>{error}</Alert>}
              <div
                className={styles.form}
              >
                <div className='inputBox'>
                  <Typography variant='body2'>First name</Typography>
                  <TextField
                    placeholder="Davinci Tolero"
                    name="firstName"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    about="loginput"
                  />
                  {formik.errors.firstName && formik.touched.firstName && <Typography variant='body2' color="red">{formik.errors.firstName}</Typography>}
                </div>
                <div className='inputBox'>
                  <Typography variant='body2'>Last name</Typography>
                  <TextField
                    placeholder="Davinci Tolero"
                    name="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    about="loginput"
                  />
                  {formik.errors.lastName && formik.touched.lastName && <Typography variant='body2' color="red">{formik.errors.lastName}</Typography>}
                </div>
                <div className='inputBox'>
                  <Typography variant='body2'>Email adress</Typography>
                  <TextField
                    placeholder="username@gmail.com"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    about="loginput"
                  />
                  {formik.errors.email && formik.touched.email && <Typography variant='body2' color="red">{formik.errors.email}</Typography>}
                </div>
                <div className='inputBox'>
                  <Typography variant='body2'>Password</Typography>
                  <TextField
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    about="loginput"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword}>
                            {showPassword ? <img src={visibilityIcon} alt="eye icon" /> : <img src={visibilityOffIcon} alt="eye icon" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.errors.password && formik.touched.password && <Typography variant='body2' color="red">{formik.errors.password}</Typography>}
                </div>
                <div className='inputBox'>
                  <Typography variant='body2'>Confirm Password</Typography>
                  <TextField
                    placeholder="Password"
                    type={showPasswordConfirm ? 'text' : 'password'}
                    name="comfirmPassword"
                    onChange={formik.handleChange}
                    value={formik.values.comfirmPassword}
                    about="loginput"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePasswordConfirm}>
                            {showPasswordConfirm ? <img src={visibilityIcon} alt="eye icon" /> : <img src={visibilityOffIcon} alt="eye icon" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.errors.comfirmPassword && formik.touched.comfirmPassword && <Typography variant='body2' color="red">{formik.errors.comfirmPassword}</Typography>}
                </div>
                <div className='checkbox'>
                  <Field type="checkbox" name="acceptedTerms" />
                  <div className='checkinfo'>
                    <Typography variant='body2' >I agree to </Typography>
                    <Typography variant='body2' color="primary">terms and privacy </Typography>
                  </div>
                </div>
                {formik.errors.acceptedTerms && formik.touched.acceptedTerms && <Typography variant='body2' color="red">{formik.errors.acceptedTerms}</Typography>}
                <div className='inputBox'>
                  <Button variant='contained' color='primary' type='submit'>Sign Up</Button>
                </div>
                <div className='linkTo'>
                  <Typography variant='body2'>Have an account? </Typography>
                  <Typography variant='body2' className='link' onClick={handlePush}> Login </Typography>
                </div>
              </div>
            </Form>
          )
        }
      }
    </Formik>
  );
}
