import * as THREE from 'three';
export function getClickedMesh(event, camera, scene,width,height,element) {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    // Get the position of the div element relative to the viewport
    const rect = element.getBoundingClientRect();

    // Calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components, relative to the div element
    mouse.x = ((event.clientX - rect.left) / width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / height) * 2 + 1;

    // Set raycaster position and direction based on mouse position
    raycaster.setFromCamera(mouse, camera);

    // Find all objects intersected by the raycaster
    const intersects = raycaster.intersectObjects(scene.children, true);

    // Return the first intersected mesh, if any
    if (intersects.length > 0) {
        return intersects[0].object;
    }

    // If no meshes were clicked, return null
    return null;
}


