import { useState, useEffect } from "react"
import { apiRoutes, unAuthetticatedAxios } from "../../utils/api"

export const useComfirm = (token) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)

    const handleComfirmEmail = async () => {
        try {
            setLoading(true)
            setData(null)
            setError(null)
            const result = await unAuthetticatedAxios.get(apiRoutes.comfirmEmail(token))
            if (result) {
                setLoading(false)
                setData(result.data)
            }
        } catch (error) {
            setError(error.response.data)
            setLoading(false)
        }
    }

    useEffect(() => {
        handleComfirmEmail()
    }, [])

    return { loading, data, error }
}