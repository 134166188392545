import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useFileUpload } from '../../hooks/UseFileUpload';
import { DELETE_TEXTURE } from '../../graphql/deleteTexture.mutation';
import { SINGLE_TEXTURE_INFO } from '../../graphql/singleTextureInfo.query';

export const useTextureDelete = (key) => {
    const [deleteHasura, { data, loading, error }] = useMutation(DELETE_TEXTURE)
    const [textureInformation, { data: textureData, loading: textureLoading, error: textureError }] = useLazyQuery(SINGLE_TEXTURE_INFO)
    const { loading: deleteImgLoading, error: deleteImgError, data: deleteImgData, handleFileDelete: deleteImg } = useFileUpload(true)
    const [hookError, setHookError] = useState(null)

    const handleDelete = async () => {
        try {
            setHookError(null)
            const textureInfo = await textureInformation({ variables: { id: key } })
            const placeholdereUrl = await deleteImg(textureInfo.data.users_textures_by_pk.file_key)
            if (placeholdereUrl) {
                await deleteHasura({ variables: { id: key } })
            }
        } catch (error) {
            console.log(error)
            setHookError("an error occured when deleting the texture file")
        }
    }

    const loadingState = loading || textureLoading || deleteImgLoading
    const errorState = error || textureError || deleteImgError || hookError
    const dataState = data && textureData && deleteImgData

    return { loadingState, errorState, dataState, handleDelete }
}