import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Alert } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styles from "./deletePopup.module.scss";

export default function DeletePopup(props) {
  const { showDeletePopUp, setShowDeletePopUp, deleteId, refetch, useGlbDelete, title, body, error, deteleBtn } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = () => {
    setShowDeletePopUp(false);
  };

  const { loadingState, errorState, dataState, handleDelete } = useGlbDelete(deleteId)

  useEffect(() => {
    if (dataState) {
      refetch()
      handleClose()
    }
  }, [dataState, handleClose])


  return (
    <div className={styles.deletePopup}>
      <Dialog
        fullScreen={fullScreen}
        open={showDeletePopUp}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        about="uploadglb"
      >
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: (errorState || loadingState) && "10px" }}>
            {errorState && <Alert severity="error">{error}</Alert>}
            {loadingState && <Alert severity="info">Loading...</Alert>}
          </div>
          <DialogContentText>
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="about" about="light" disabled={loadingState} >
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="about" about="purple" disabled={loadingState} >
            {deteleBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
