export const appRoutes = {
    home: "/:id",
    listOfProducts: "/listOfProducts",
    editProduct: "/editproduct",
    login: "/login",
    signup: "/signup",
    confirmEmail: "/auth/confirmation/:token",
    uploadGlb: "/upload-glb",
    editGlb: "/edit-glb/:id",
    manageGlb: "/manage-glb",
    uplaodTextures: "/upload-texture",
    manageTextures: "/manage-textures",
    editTextures: "/edit-textures/:id",
    uploadGlbHdrs: "/upload-hrds",
    manageGlbHdrs: "/manage-hrds",
    editGlbHdrs: "/edit-hdri/:id",
    userProfile: "/userProfile",
    publicProfile: "/publicProfile/:id",
    publicProfileFunc: (id) => `/publicProfile/${id}`,
    // end user routes 
    endUserGlbs: "/endUserGlbs",
    likedGlbs: "/likedGlbs",
    landing: "/",
}