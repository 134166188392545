import { Typography } from "@mui/material";
import React from "react";
import styles from "./ImageSliders.module.scss";
import imageSlides from "./slidesData";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function ImageSliders() {
  return (
    <div id={styles.imageSliders}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        swipeable={true}
        transitionTime={500}
        about="slides"
      >
        {imageSlides &&
          imageSlides.map((slide, id) => {
            return (
              <div className="box_slider" key={id}>
                <div className="top">
                  <div
                    dangerouslySetInnerHTML={{ __html: slide.title }}
                    className="title"
                  />
                  <Typography variant="body2" className="subtitle">
                    {slide.sub_title}
                  </Typography>
                </div>
                <img className="sliders" src={slide.url} alt="silde single_Pic" />
              </div>
            );
          })}
      </Carousel>
    </div>
  );
}
