import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Home from "./pages/Home/Home";
import "./index.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { defaultTheme } from "./mui/theme";
import LoginSignupPages from "./pages/LoginSignupPages/LoginSignupPages";
import ComfirmEmail from "./pages/comfirmEmail/ComfirmEmail";
import UploadGlb from "./pages/UploadGlbPage/UploadGlb";
import ManageGlb from "./pages/manageGlbPage/ManageGlb";
import { appRoutes } from "./utils/appRoutes";
import UploadTexturesPage from "./pages/UploadTexturesPage/UploadTexturesPage";
import ManageTexture from "./pages/manageTexturesPage/ManageTexture";
import UploadHDRIPage from "./pages/UploadHdriPage/UploadHdriPage";
import ManageHdri from "./pages/manageHdriPage/ManageHdri";
import ListOfProducts from "./pages/listOfProducts/ListOfProducts";
import UnAutheticatedGraphql from "./providers/UnAutheticatedGraphql";
import GaurdClientRoutes from "./gaurds/GaurdClientRoutes";
import GaurdEndUserRoutes from "./gaurds/GaurdEndUserRoutes";
import ManageMods from "./pages/manageModsPage/ManageMods";
import UserProfile from "./pages/manageUserProfile/userProfile";
import PublicProfile from "./pages/publicProfile/publicProfile";
import PublicLayout from "./layout/publicPagesLayout.layout";
import GaurdClientAndEndUser from "./gaurds/GaurdClientAndEndUser";
import ManageLikes from "./pages/manageEndUserLikesPage/ManageLikes";
import LandingPage from "./pages/landing/LandingPage";

export default function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="app">
        <Router>
          <Routes>
            <Route path={appRoutes.home} exact element={(
              <UnAutheticatedGraphql><PublicLayout><Home /></PublicLayout></UnAutheticatedGraphql>
            )} />
            <Route path={appRoutes.editProduct} exact element={<PublicLayout><Home /></PublicLayout>} />
            <Route path={appRoutes.listOfProducts} element={<PublicLayout><ListOfProducts /></PublicLayout>} />
            <Route path={appRoutes.login} element={<LoginSignupPages />} />
            <Route path={appRoutes.signup} element={<LoginSignupPages />} />
            <Route
              path={appRoutes.confirmEmail}
              element={<ComfirmEmail />}
            />
            <Route path={appRoutes.uploadGlb} element={(
              <GaurdClientRoutes><UploadGlb /></GaurdClientRoutes>
            )} />
            <Route path={appRoutes.editGlb} element={(
              <GaurdClientRoutes><UploadGlb edit /></GaurdClientRoutes>
            )} />
            <Route path={appRoutes.manageGlb} element={(
              <GaurdClientRoutes><ManageGlb /></GaurdClientRoutes>
            )} />
            <Route path={appRoutes.uplaodTextures} element={(
              <GaurdClientRoutes><UploadTexturesPage /></GaurdClientRoutes>
            )} />
            <Route path={appRoutes.manageTextures} element={(
              <GaurdClientRoutes>
                <ManageTexture />
              </GaurdClientRoutes>
            )} />
            <Route path={appRoutes.editTextures} element={(
              <GaurdClientRoutes><UploadTexturesPage edit /></GaurdClientRoutes>
            )} />
            <Route path={appRoutes.uploadGlbHdrs} element={(
              <GaurdClientRoutes><UploadHDRIPage /></GaurdClientRoutes>
            )} />
            <Route path={appRoutes.editGlbHdrs} element={(
              <GaurdClientRoutes><UploadHDRIPage edit /></GaurdClientRoutes>
            )} />
            <Route path={appRoutes.manageGlbHdrs} element={(
              <GaurdClientRoutes><ManageHdri /></GaurdClientRoutes>
            )} />
            <Route path={appRoutes.userProfile} element={(
              <GaurdClientAndEndUser><UserProfile /></GaurdClientAndEndUser>
            )} />
            <Route path={appRoutes.publicProfile} element={(<PublicProfile />)} />

            {/* end user pages */}
            <Route path={appRoutes.endUserGlbs} element={(
              <GaurdEndUserRoutes><ManageMods /></GaurdEndUserRoutes>
            )} />
            <Route path={appRoutes.likedGlbs} element={(
              <GaurdEndUserRoutes><ManageLikes /></GaurdEndUserRoutes>
            )} />
            <Route path={appRoutes.landing} element={(
              <LandingPage />
            )} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}
