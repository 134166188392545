import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
export function loadHDRI(url, renderer, scene, LoadingManager,setPercentage=()=>{}) {
    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    const loader = new RGBELoader(LoadingManager);
    loader.onProgress = function (xhr) {
        const percentComplete = xhr.loaded / xhr.total * 100;
    };
    // Load the HDRI image
    loader.load(url, function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        scene.environment = envMap;
        scene.background = envMap;
        texture.dispose();
        pmremGenerator.dispose();
    }, function (xhr) {
        const percentComplete = xhr.loaded / xhr.total * 100;
        setPercentage(percentComplete)
    });
}
