import { Button, Typography, Stack, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeletePopup from "../../components/deletePopup/deletePopup";
import EmptyFilter from "../../components/emptyFilter/emptyFilter";
import PreviewPopup from "../../components/previewPopup/previewPopup";
import styles from "./ManageGlb.module.scss";
import { useLazyQuery, useQuery } from "@apollo/client";
import { LIST_OF_GLBS, NUMBER_OF_GLBS_LIKES } from "../../graphql/listOfGlbs.query";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { Link } from "react-router-dom";
import { useGlbDelete } from "../../components/deletePopup/useDeleteGlb.hook";
import { useDispatch, useSelector } from "react-redux";
import { setSearchInput } from "../../components/topNav/topNav.slice";
import UnAutheticatedGraphql from "../../providers/UnAutheticatedGraphql";

export default function ManageGlb() {
  const searchInputValue = useSelector((state) => state.headerReducers.searchInput);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [showPreviewPopUp, setShowPreviewPopUp] = useState({ permition: false, image: "" });
  const [searchValue, setSearchValue] = useState(searchInputValue);
  const [deleteId, setDeleteId] = useState(null);

  const dispatch = useDispatch();
  const handleReset = () => {
    setSearchValue("");
    dispatch(setSearchInput(""));
  };
  useEffect(() => setSearchValue(searchInputValue), [searchInputValue]);

  const { data: listOfGlbs, refetch } = useQuery(LIST_OF_GLBS(searchValue), { fetchPolicy: "no-cache" });

  const handleDeletePopup = (id) => {
    setShowDeletePopUp(true);
    setDeleteId(id);
  };
  const handlePreviewPopup = (id, image) => {
    setShowPreviewPopUp({ permition: true, image });
  };

  const handleSeachFilter = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <DashboardLayout>
      <div className={styles.manageGlb}>
        <div className="upload">
          <div className="top_content">
            <div className="title">Manage Your Glbs</div>
            <div className="sub_title">Manage your submitted glbs</div>
          </div>
          <div className="uploaded_glb">
            <div className="uploaded_glb_top">
              <Typography variant="h5" className="label">
                Uploaded Glbs
              </Typography>
              <div className="searchBox">
                <input type="text" className="search_input" placeholder="Search Glb" value={searchValue} onChange={(e) => handleSeachFilter(e)} />
                <img src="/images/search.svg" alt="search" className="search" />
              </div>
            </div>
            {listOfGlbs && listOfGlbs.users_glbs.length === 0 && (
              <EmptyFilter
                setSearchValue={handleReset}
                title="No Matching Glbs Found"
                desc=" We're sorry, but the glb search and filter you performed did not return
        any results. We recommend reviewing the filters you're using or
        resetting them altogether. To reset your glb search filter, please click
        the button below"
              />
            )}
            {listOfGlbs && listOfGlbs.users_glbs.length !== 0 && (
              <div className="uploaded_glb_body">
                <div className="title_box">
                  <Typography variant="body2" className="child">
                    #
                  </Typography>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      Glb
                    </Typography>
                  </div>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      SKU
                    </Typography>
                  </div>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      Likes
                    </Typography>
                  </div>
                  <Typography variant="body2" className="child">
                    status
                  </Typography>
                  <Typography variant="body2" className="child">
                    Preview Glb
                  </Typography>
                  <Typography variant="body2" className="child">
                    Edit Glb info
                  </Typography>
                  <Typography variant="body2" className="child">
                    Delete Glb
                  </Typography>
                </div>
                <div className="content_box">
                  {showDeletePopUp && (
                    <DeletePopup
                      showDeletePopUp={showDeletePopUp}
                      setShowDeletePopUp={setShowDeletePopUp}
                      deleteId={deleteId}
                      refetch={refetch}
                      useGlbDelete={useGlbDelete}
                      title="Are You Sure You Want To Delete This Glb?"
                      body="Please confirm that you want to delete this glb permanently from the
            system. This action cannot be undone, and any associated data and
            metadata, such as annotations, tags, and descriptions, will be
            deleted along with the glb."
                      error="An error ocuured when trying to delete glb"
                      deteleBtn="Delete Glb"
                    />
                  )}
                  {showPreviewPopUp && <PreviewPopup showPreviewPopUp={showPreviewPopUp} setShowPreviewPopUp={setShowPreviewPopUp} glb />}
                  {listOfGlbs &&
                    listOfGlbs.users_glbs.map((item, id) => {
                      return <SingleGlb id={id} item={item} handlePreviewPopup={handlePreviewPopup} handleDeletePopup={handleDeletePopup} />;
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
function SingleGlb({ id, item, handlePreviewPopup, handleDeletePopup }) {
  return (
    <div key={id} className="row">
      <Typography variant="body2" className="child">
        {id + 1}
      </Typography>
      <div className="glb">
        <img src={item.placeholder_image_url} alt="imageLogo" className="image" />
        <Typography variant="body2" className="child">
          {item.name}
        </Typography>
      </div>
      <Typography variant="body2" className="child">
        {item.SKU}
      </Typography>
      <Typography variant="body2" className="child">
        <UnAutheticatedGraphql>
          <UserLikesNumber id={item.id} />
        </UnAutheticatedGraphql>
      </Typography>
      <Stack direction="row" spacing={1}>
        <Chip label={item.private ? "draft" : "published"} className={item.private ? "draft child" : "published child"} />
      </Stack>
      <Button variant="outlined" className="preview child" onClick={() => handlePreviewPopup(id, item.glb_file_url)}>
        Preview Glb
      </Button>
      <Link to={`/edit-glb/${item.id}`}>
        <Button variant="outlined" className="edit child">
          Edit Glb Info
        </Button>
      </Link>
      <Button variant="outlined" className="delete child" onClick={() => handleDeletePopup(item.id)}>
        Delete Glb
      </Button>
    </div>
  );
}

function UserLikesNumber({ id }) {
  const { data: likes } = useQuery(NUMBER_OF_GLBS_LIKES, { variables: { id: id } });
  return (
    <>
      {likes && likes.user_likes_aggregate.aggregate.count > 0 && (
        <div className="d-flex">
          <img src="./images/heart.svg" alt="" />
          {likes && likes.user_likes_aggregate.aggregate.count}
        </div>
      )}
    </>
  );
}
