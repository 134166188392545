import React, { useState } from "react";
import FormUpload from "./components/FormUpload/FormUpload";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import FormLayout from "../../components/dashboardLayout/FormLayout.layout";

const glbPlaceholder = "/images/upload_placeholder_image.svg";

export default function UploadTexturesPage(props) {
  const [placeholderPreview, setPlaceholderPreview] = useState(glbPlaceholder);

  return (
    <DashboardLayout>
      <FormLayout
        title="Upload Your Texture"
        subTitle="Submit Your Texture: Name, File, Rarity, and associated glb"
        imagePreview={placeholderPreview}
        imagePreviewTitle="Texture Preview"
        {...props}
      >
        <FormUpload
          setPlaceholderPreview={setPlaceholderPreview}
        />
      </FormLayout>
    </DashboardLayout>
  );
}
