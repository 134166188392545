import { gql } from "@apollo/client";

export const SINGLE_TEXTURE_INFO = gql`
query SINGLE_TEXTURE_INFO($id:uuid!) {
  users_textures_by_pk(id: $id){
    associated_glb
    created_at
    file_key
    file_url
    id
    rarity
    users_glb {
      id
    }
    texture_name
  }
}
`