import React, { useRef, useState } from "react";
import styles from "./StableDiffusion.module.scss";
import { Alert, Button, CircularProgress, Typography } from "@mui/material";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { setDetails } from "./autoGenerated.slice";
import { imagesData, updateContent } from "./images.data";
import CardBlurPopup from "../CardBlurPopup/CardBlurPopup";
import UpdateTexturePopUp from "./components/updateTexturePopUp/updateTexturePopUp";
import Popover from "@mui/material/Popover";
import { useStableDiffusion } from "./useStableDiffusion";

function StableDiffusion() {
  const [texture, setTexture] = useState(null);
  const [cardId, setCardId] = useState(null);
  const [showSmallPopup, setShowSmallPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const detailState = useSelector((state) => state.imageDetailsReducer.details);
  const popUpContent = useSelector((state) => state.imageDetailsReducer.cardPopUp);

  const handleChangeDetails = (e) => {
    dispatch(setDetails(e.target.value));
  };

  const { loading, error, handlePrompt, images } = useStableDiffusion();
  const handleSubmitImageDetails = (e) => {
    e.preventDefault();
    handlePrompt(detailState);
  };
  const handleClickedCard = (id, image, e) => {
    setTexture(image);
    setShowSmallPopup(true);
    setCardId(id);
  };

  const handleClickOutsideCard = (id) => {
    setShowSmallPopup(false);
  };

  const handleClickOpenPopOver = (event, id) => {
    setAnchorEl(event.currentTarget);
    setCardId(id);
  };
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };
  //load the content into editor
  const handleLoadIntoEditor = () => {
    dispatch(setDetails(popUpContent));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className={styles.autoGenerated}>
      <form action="POST" onSubmit={handleSubmitImageDetails} className="form">
        <Typography variant="h6" color="dark" className="header">
          Describe your image
        </Typography>
        <textarea name="details" id="details" value={detailState} onChange={handleChangeDetails} className="details" placeholder="vintage ornate mushroom and foliage wallpaper risograph print halftone pattern blue and red" rows={5}></textarea>
        <div className="action">
          <Button variant="contained" color="primary" type="submit" sx={{ borderRadius: "0px" }}>
            Generate
          </Button>
        </div>
      </form>
      <div className="container_images">
        <Typography variant="h4">Generated AI Images</Typography>
        <div className="images_box">
          {loading && (
            <div className="loading-card">
              <CircularProgress />
            </div>
          )}
          {error && (
            <div className="error-card">
              <div className="erro-text-container">
                <img src="./images/oopsError.svg" alt="robot error" />
                <div className="error">We were enable to generate the image we are working on the issue ASAP</div>
              </div>
            </div>
          )}
          {images &&
            images.map((card, idx) => {
              card.id = idx + 1;
              const props = { card, showSmallPopup, cardId, handleClickOutsideCard, texture, handleLoadIntoEditor, handleClickedCard, handleClickOpenPopOver, id, open, anchorEl, handleClosePopOver, setAnchorEl, handlePrompt };
              return <SingleGeneratedImage {...props} key={idx} />;
            })}
          {Array.apply(null, Array(Math.max(0, 6 - images.length))).map((card, idx) => {
            return <img key={idx} className="empty-card" src="./images/emptyAiGeneration.svg" alt="AI generated placeholder" />;
          })}
        </div>
      </div>
    </div>
  );
}

export default StableDiffusion;

function SingleGeneratedImage({ card, showSmallPopup, cardId, handleClickOutsideCard, texture, handleLoadIntoEditor, handleClickedCard, handleClickOpenPopOver, id, open, anchorEl, handleClosePopOver, setAnchorEl, handlePrompt }) {
  const link = useRef(null);
  return (
    <div key={card.id} className="card" style={{ backgroundImage: `url("${card.url}")` }}>
      {showSmallPopup && cardId === card.id && (
        <OutsideClickHandler onOutsideClick={handleClickOutsideCard}>
          <CardBlurPopup id={card.id} texture={card.url} />
        </OutsideClickHandler>
      )}
      <div className="btns_container">
        <div className="actions_top">
          <a href={card.url} target="_blank" rel="noopener noreferrer" ref={link} hidden></a>
          <div className="btn" onClick={() => link.current.click()}>
            Download Image
            <div className="btnLogo">
              <img src="./images/downloadGeneration.svg" alt="edit Icon" className="editIcon" />
            </div>
          </div>
        </div>
        <div className="clickableSeciton" onClick={() => handleClickedCard(card.id, card.image)}></div>
        <div className="actions_bottom">
          {card.id && <div className="number">{card.id < 10 ? `0${card.id}` : card.id} </div>}
          <div className="btn" onClick={(e) => handleClickOpenPopOver(e, card.id)}>
            Edit Prompt
            <div className="btnLogo">
              <img src="./images/edit-2-autlined.svg" alt="edit Icon" className="editIcon" />
            </div>
          </div>
          {cardId === card.id && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopOver}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              className="updatePopup"
              about="muiPaperPopover"
            >
              <UpdateTexturePopUp content={card.prompt} setAnchorEl={setAnchorEl} handlePrompt={handlePrompt} />
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
}
