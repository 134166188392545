import React from "react";
import styles from "./IconComponent.module.scss";

export default function IconComponent({ svg, active }) {
  return (
    <div id={styles.iconComponent}>
      <div className="container">
        <div className={`circle-1 ${active}`}></div>
        <div className={"circle-2"}></div>
        <div className="btnIcons">
          <img className="Icon" src={svg} alt="Icon" />
        </div>
      </div>
    </div>
  );
}
