import { Button, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeletePopup from "../../components/deletePopup/deletePopup";
import EmptyFilter from "../../components/emptyFilter/emptyFilter";
import PreviewPopup from "../../components/previewPopup/previewPopup";
import styles from "./ManageGlb.module.scss";
import { useQuery } from "@apollo/client";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { Link } from "react-router-dom"
// import { useGlbDelete } from "../../components/deletePopup/useDeleteGlb.hook";
import { LIST_OF_HDRI } from "../../graphql/listOfhdri.mutation";
// import { useTextureDelete } from "../../components/deletePopup/useDeleteTexture.hook";
import { useHdriDelete } from "../../components/deletePopup/useDeleteHdri.hook";
import { useDispatch, useSelector } from "react-redux";
import { setSearchInput } from "../../components/topNav/topNav.slice";

export default function ManageHdri() {
  const searchInputValue = useSelector((state) => state.headerReducers.searchInput);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [showPreviewPopUp, setShowPreviewPopUp] = useState({ permition: false, image: "" });
  const [searchValue, setSearchValue] = useState(searchInputValue);
  const [deleteId, setDeleteId] = useState(null)

  const dispatch = useDispatch();
  const handleReset = () => {
    setSearchValue("");
    dispatch(setSearchInput(""));
  };
  useEffect(() => setSearchValue(searchInputValue), [searchInputValue]);
  const { data: listOfhdri, refetch } = useQuery(LIST_OF_HDRI(searchValue), { fetchPolicy: "no-cache" })

  const handleDeletePopup = (id) => {
    setShowDeletePopUp(true);
    setDeleteId(id)
  }
  const handlePreviewPopup = (id, image) => {
    setShowPreviewPopUp({ permition: true, image, });
  }

  const handleSeachFilter = (e) => {
    setSearchValue(e.target.value);
  }

  return (
    <DashboardLayout>
      <div className={styles.manageGlb}>
        <div className="upload">
          <div className="top_content">
            <div className="title">Manage Your hdri</div>
            <div className="sub_title">Manage hdri for glbs</div>
          </div>
          <div className="uploaded_glb">
            <div className="uploaded_glb_top">
              <Typography variant="h5" className="label">
                Uploaded hdri
              </Typography>
              <div className="searchBox">
                <input type="text" className="search_input" placeholder="Search Hdri" value={searchValue} onChange={(e) => handleSeachFilter(e)} />
                <img src="/images/search.svg" alt="search" className="search" />
              </div>
            </div>
            {listOfhdri && listOfhdri.users_hdri.length === 0 && (
              <EmptyFilter
                setSearchValue={handleReset}
                title="No Matching hdri Found"
                desc="We're sorry, but the texture search and filter you performed did not return any results. We recommend reviewing the filters you're using or resetting them altogether. To reset your texture search filter, please click the button below"
              />
            )}
            {listOfhdri && listOfhdri.users_hdri.length !== 0 && (
              <div className="uploaded_glb_body">
                <div className="title_box">
                  <Typography variant="body2" className="child">
                    #
                  </Typography>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      Hdri
                    </Typography>
                  </div>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      Associated Glb
                    </Typography>
                  </div>
                  <div></div>
                  <Typography variant="body2" className="child">
                    Preview Hdri
                  </Typography>
                  <Typography variant="body2" className="child">
                    Edit Hdri info
                  </Typography>
                  <Typography variant="body2" className="child">
                    Delete Hdri
                  </Typography>
                </div>
                <div className="content_box">
                  {showDeletePopUp && (
                    <DeletePopup
                      showDeletePopUp={showDeletePopUp}
                      setShowDeletePopUp={setShowDeletePopUp}
                      deleteId={deleteId}
                      refetch={refetch}
                      useGlbDelete={useHdriDelete}
                      title="Are You Sure You Want To Delete This HDRI?"
                      body="Please confirm that you want to delete this HDRI permanently from the system. This action cannot be undone, and any associated data and metadata and descriptions, will be deleted along with the HDRI."
                      deteleBtn="Delete HDRI"
                      error="error deleting HDRI"
                    />
                  )}
                  {showPreviewPopUp && <PreviewPopup showPreviewPopUp={showPreviewPopUp} setShowPreviewPopUp={setShowPreviewPopUp} hdri />}
                  {listOfhdri &&
                    listOfhdri.users_hdri.map((item, id) => {
                      return (
                        <div key={id} className="row">
                          <Typography variant="body2" className="child">
                            {id + 1}
                          </Typography>
                          <div className="glb">
                            <img src="/images/hdriImage.svg" alt="imageLogo" className="image" />
                            <Typography variant="body2" className="child">
                              {item.hdri_name}
                            </Typography>
                          </div>
                          <Link to={`/edit-glb/${item.users_glb.id}`}>
                            <Button variant="contained" sx={{ opacity: 0.3 }}>
                              View Associated Glb
                            </Button>
                          </Link>
                          <div></div>
                          <Button variant="outlined" className="preview child" onClick={() => handlePreviewPopup(id, item.file_url)}>
                            Preview Hdri
                          </Button>
                          <Link to={`/edit-hdri/${item.id}`}>
                            <Button variant="outlined" className="edit child">
                              Edit Hdri Info
                            </Button>
                          </Link>
                          <Button variant="outlined" className="delete child" onClick={() => handleDeletePopup(item.id)}>
                            Delete Hdri
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
