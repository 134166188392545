import * as React from "react";
import styles from "./FormUpload.module.scss";
import { Button, Typography, TextField, Select, MenuItem, Alert, Skeleton } from "@mui/material";
import { useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FileUploadComponent from "../../../../components/FileUpload/FileUploadComponent.component";
import { ACCEPTED_PROFILE_IMAGE_MB, ACCEPTED_PROFILE_IMAGE_SIZE, SUPPORTED__IMAGE_FORMATS } from "../../../../utils/constants";
import { useQuery } from "@apollo/client";
import { LIST_OF_INDUSTRIES } from "../../../../graphql/listOfIndustries.query";
import { useHandleUserProfile } from "./useHandleUserProfile.hook";
import { GET_USER_PROFILE } from "../../../../graphql/userProfileInfo.query";

export default function FormUpload(props) {
  const { setPlaceholderPreview } = props;
  const [placeholderImageName, setPlaceholderImageName] = useState(null);

  const handleRemovePlaceholder = () => {
    setPlaceholderPreview(null);
    setPlaceholderImageName(null);
  };

  const submitBtnRef = useRef();
  const handleSubmitBtn = (e, setFieldValue, value, formikValues) => {
    setFieldValue("isPrivate", value);
    submitBtnRef.current.click();
  };

  const { loading: loadingUpdateUserProfile, error: errorUpdateUserProfile, data: dataUpdateUserProfile, handler, doUserProfileExist } = useHandleUserProfile();
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, "Your username is too short!")
      .max(50, "Your username is too long!")
      .required("Username is required")
      .matches(/^[a-zA-Z0-9_]+$/, "Only alphanumeric characters and underscores are allowed")
      .test("userExist","A user with this username already exists", async (value) => {
        const res=await doUserProfileExist(value);
        return !res;
      }),
    location: Yup.string().optional(),
    industry: Yup.number().optional().positive("Please select an industry"),
    description: Yup.string().optional().min(2, 'The description is too short.').max(300, 'The description is should not exceed 300 characters.'),
    placeholderImage: Yup.mixed()
      .required("A placeholder Image file is required")
      .test("fileSize", `file size must be ${ACCEPTED_PROFILE_IMAGE_MB} or smaller`, (value) => {
        if (typeof value === "string") {
          return true;
        }
        return value && value.size <= ACCEPTED_PROFILE_IMAGE_SIZE;
      })
      .test("fileFormat", `Supported formats: ${SUPPORTED__IMAGE_FORMATS.join(", ")}`, (value) => {
        if (typeof value === "string") {
          return true;
        }
        return value && SUPPORTED__IMAGE_FORMATS.includes(value.type);
      }),
  });

  const { data: dataProfileInfo } = useQuery(GET_USER_PROFILE, { fetchPolicy: "no-cache" });

  React.useEffect(() => {
    if (!placeholderImageName && dataProfileInfo && dataProfileInfo?.users_general_information.length > 0) {
      setPlaceholderImageName(dataProfileInfo.users_general_information[0].user_profile_picture?.split("/").pop());
      setPlaceholderPreview(dataProfileInfo.users_general_information[0].user_profile_picture);
    }
  }, [dataProfileInfo]);

  const handleInitialValues = () => {
    if (dataProfileInfo && dataProfileInfo?.users_general_information.length > 0) {
      return {
        username: dataProfileInfo.users_general_information[0]?.username || "",
        industry: dataProfileInfo.users_general_information[0]?.industry || -1,
        location: dataProfileInfo.users_general_information[0]?.location || "",
        description: dataProfileInfo.users_general_information[0]?.bio || "",
        fileKey: dataProfileInfo.users_general_information[0]?.profile_key || "",
        fileUrl: dataProfileInfo.users_general_information[0]?.user_profile_picture || "",
        id: dataProfileInfo.users_general_information[0]?.id || "",
        placeholderImage: dataProfileInfo.users_general_information[0]?.user_profile_picture.split("/").pop() || "",
      };
    } else {
      return {
        username: "",
        industry: -1,
        location: "",
        description: "",
        fileKey: "",
        fileUrl:"",
        placeholderImage: "",
      };
    }
  };

  const { loading, data, error } = useQuery(LIST_OF_INDUSTRIES);

  const handleSubmit = async (values) => {
    await handler(values);
  };
  return (
    <>
      <Formik validationSchema={validationSchema} initialValues={handleInitialValues()} onSubmit={handleSubmit} enableReinitialize>
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          const handlePlaceholderChange = (files) => {
            const selectedFile = files[0];
            setFieldValue("placeholderImage", selectedFile);
            if (selectedFile) {
              const blobUrl = URL.createObjectURL(selectedFile);
              setPlaceholderImageName(selectedFile.name);
              setPlaceholderPreview(blobUrl);
            } else {
              setPlaceholderPreview(null);
              setPlaceholderImageName(null);
            }
          };
          const imageFileProps = {
            handleRemoveGlb: handleRemovePlaceholder,
            handleGlbChange: handlePlaceholderChange,
            fileUrl: placeholderImageName,
            errors: errors.placeholderImage,
            touched: touched.placeholderImage,
            title: "Profile Picture",
            fileTypes: ".jpeg,.png,.webp,.jpg",
            subTitle: "(Add a picture of you, to help people recognize you.)",
            mimeTypes: {
              "image/jpeg": [".jpeg"],
              "image/jpg": [".jpg"],
              "image/png": [".png"],
              "image/webp": [".webp"],
            },
            maxFileSize: 2,
            dropTitle: "Upload Your Profile Picture",
            uploadSvg: "/images/img.svg",
            fileType: null,
          };
          return (
            <Form className={styles.formUpload} autoComplete="off">
              <div className="form_content">
                {loadingUpdateUserProfile && <Alert severity="info">{loadingUpdateUserProfile}</Alert>}
                {errorUpdateUserProfile && <Alert severity="error">An error occured</Alert>}
                {dataUpdateUserProfile && <Alert severity="success">Profile updated successfully</Alert>}
                <>
                  <div className="labelBox">
                    <Typography variant="body2" className="label">
                      Username&nbsp;&nbsp;
                    </Typography>
                    <div className="opacity">
                      <Typography variant="body2" className="label" color="secondary">
                        (Change your username here that help people to find you)
                      </Typography>
                      <Typography variant="body2" className="label purple" about="purple">
                        *
                      </Typography>
                    </div>
                  </div>
                  <TextField variant="outlined" classes={{ root: "input" }} placeholder='Example: "Davinci John"' onChange={handleChange} value={values.username} name="username" id="username" />
                  {errors.username && touched.username && (
                    <Typography variant="body2" className="label" color="red">
                      {errors.username}
                    </Typography>
                  )}
                </>
                <>
                  <div className="labelBox">
                    <Typography variant="body2" className="label">
                      Your Industry&nbsp;&nbsp;
                    </Typography>
                    <div className="opacity">
                      <Typography variant="body2" className="label" color="secondary">
                        (Add your industry here)
                      </Typography>
                    </div>
                  </div>
                  {loading && <Skeleton variant="rectangular" height={56} about="rounded" />}
                  {error && <Alert severity="error">An error occured when fecthing list of industries </Alert>}
                  {data && (
                    <>
                      <Select onChange={handleChange} name="industry" value={values.industry}>
                        <MenuItem value={-1}>Select an industry</MenuItem>;
                        {data.industries.map((industry, index) => {
                          return (
                            <MenuItem key={index} value={industry.id}>
                              {industry.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.industry && touched.industry && (
                        <Typography variant="body2" className="label" color="red">
                          {errors.industry}
                        </Typography>
                      )}
                    </>
                  )}
                </>
                <>
                  <div className="labelBox">
                    <Typography variant="body2" className="label">
                      Location&nbsp;&nbsp;
                    </Typography>
                    <div className="opacity">
                      <Typography variant="body2" className="label" color="secondary">
                        (Add your Location, to help people find you)
                      </Typography>
                    </div>
                  </div>
                  <TextField variant="outlined" classes={{ root: "input" }} placeholder='Example: "Kigali"' onChange={handleChange} value={values.location} name="location" id="location" />
                  {errors.location && touched.location && (
                    <Typography variant="body2" className="label" color="red">
                      {errors.location}
                    </Typography>
                  )}
                </>
                <div className="quil">
                  <div className="labelBox">
                    <Typography variant="body2" className="label">
                      Description&nbsp;&nbsp;
                    </Typography>
                    <div className="opacity">
                      <Typography variant="body2" className="label" color="secondary">
                        (Add a Short bio to descibe yourself)
                      </Typography>
                    </div>
                  </div>
                  <textarea placeholder="Enter your short Bio" onChange={handleChange} name="description" id="description" value={values.description} className="quilInput" />
                  {errors.description && touched.description && (
                    <Typography variant="body2" className="label" color="red">
                      {errors.description}
                    </Typography>
                  )}
                </div>
                <FileUploadComponent {...imageFileProps} />
              </div>
              <div className="buttons">
                <button type="submit" hidden ref={submitBtnRef}></button>
                <Button
                  type="submit"
                  className="btn final"
                  variant="contained"
                  color="primary"
                  value={false}
                  onClick={(e) => {
                    handleSubmitBtn(e, setFieldValue, false, values);
                  }}
                  // disabled={Boolean(loadingState)}
                >
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
