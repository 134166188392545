import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeletePopup from "../../components/deletePopup/deletePopup";
import EmptyFilter from "../../components/emptyFilter/emptyFilter";
import PreviewPopup from "../../components/previewPopup/previewPopup";
import styles from "./ManageMods.module.scss";
import { useQuery } from "@apollo/client";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { useNavigate } from "react-router-dom";
import { LIST_OF_MODS } from "../../graphql/listOdUserGlbs.query";
import { useUserGlbDelete } from "../../components/deletePopup/useDeleteMod.hook";
import { useDispatch, useSelector } from "react-redux";
import { setSearchInput } from "../../components/topNav/topNav.slice";

export default function ManageMods() {
  const searchInputValue = useSelector((state) => state.headerReducers.searchInput);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [showPreviewPopUp, setShowPreviewPopUp] = useState({ permition: false, image: "" });
  const [searchValue, setSearchValue] = useState(searchInputValue);
  const [deleteId, setDeleteId] = useState(null);

  const dispatch = useDispatch();
  const handleReset = () => {
    setSearchValue("");
    dispatch(setSearchInput(""));
  };
  useEffect(() => setSearchValue(searchInputValue), [searchInputValue]);
  const { data: listOfGlbs, refetch } = useQuery(LIST_OF_MODS(searchValue), { fetchPolicy: "no-cache" });

  const handleDeletePopup = (id) => {
    setShowDeletePopUp(true);
    setDeleteId(id);
  };
  const handlePreviewPopup = (id, image) => {
    setShowPreviewPopUp({ permition: true, image });
  };

  const handleSeachFilter = (e) => {
    setSearchValue(e.target.value);
  };

  const navigate = useNavigate();
  const handleGlbToedit = (glb, id, name, modId) => {
    navigate(`/${id}?glbToUse=${glb}&edit=true&name=${name}&modId=${modId}`);
  };

  return (
    <DashboardLayout>
      <div className={styles.manageGlb}>
        <div className="upload">
          <div className="top_content">
            <div className="title">Manage Your Mods</div>
            <div className="sub_title">Manage your modified items ready to be printed</div>
          </div>
          <div className="uploaded_glb">
            <div className="uploaded_glb_top">
              <Typography variant="h5" className="label">
                Uploaded Mods
              </Typography>
              <div className="searchBox">
                <input type="text" className="search_input" placeholder="Search Mod" value={searchValue} onChange={(e) => handleSeachFilter(e)} />
                <img src="/images/search.svg" alt="search" className="search" />
              </div>
            </div>
            {listOfGlbs && listOfGlbs.user_moded_glb.length === 0 && (
              <EmptyFilter
                setSearchValue={handleReset}
                title="No Matching Mods Found"
                desc="We're sorry, but the mods search and filter you performed did not return any results. We recommend reviewing the filters you're using or resetting them altogether. To reset your mods search filter, please click the button below:"
              />
            )}
            {listOfGlbs && listOfGlbs.user_moded_glb.length !== 0 && (
              <div className="uploaded_glb_body">
                <div className="title_box">
                  <Typography variant="body2" className="child">
                    #
                  </Typography>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      Mods
                    </Typography>
                  </div>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      SKU
                    </Typography>
                  </div>
                  <Typography variant="body2" className="child">
                    View Mod
                  </Typography>
                  <Typography variant="body2" className="child">
                    Edit Mod
                  </Typography>
                  <Typography variant="body2" className="child">
                    Delete Mod
                  </Typography>
                </div>
                <div className="content_box">
                  {showDeletePopUp && (
                    <DeletePopup
                      showDeletePopUp={showDeletePopUp}
                      setShowDeletePopUp={setShowDeletePopUp}
                      deleteId={deleteId}
                      refetch={refetch}
                      useGlbDelete={useUserGlbDelete}
                      title="Are You Sure You Want To Delete This Mod?"
                      body="Please confirm that you want to delete this mod permanently from the system. This action cannot be undone, and any associated data and metadata, such as annotations, tags, and descriptions, will be deleted along with the mod."
                      error="An error ocuured when trying to delete glb"
                      deteleBtn="Delete Mod"
                    />
                  )}
                  {showPreviewPopUp && <PreviewPopup showPreviewPopUp={showPreviewPopUp} setShowPreviewPopUp={setShowPreviewPopUp} mod />}
                  {listOfGlbs &&
                    listOfGlbs.user_moded_glb.map((item, id) => {
                      return (
                        <div key={id} className="row">
                          <Typography variant="body2" className="child">
                            {id + 1}
                          </Typography>
                          <div className="glb">
                            <img src={item.placeholder_image_url} alt="imageLogo" className="image" />
                            <Typography variant="body2" className="child">
                              {item.name}
                            </Typography>
                          </div>
                          <Typography variant="body2" className="child">
                            {item.users_glb.SKU}
                          </Typography>
                          <Button variant="outlined" className="preview child" onClick={() => handlePreviewPopup(id, item.glb_file_url)}>
                            Preview Glb
                          </Button>
                          <Button variant="outlined" className="edit child" onClick={() => handleGlbToedit(item.glb_file_url, item.users_glb.id, item.name, item.id)}>
                            Edit Mod
                          </Button>
                          <Button variant="outlined" className="delete child" onClick={() => handleDeletePopup(item.id)}>
                            Delete Glb
                          </Button>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
