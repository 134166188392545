import { useState } from "react";
import { apiRoutes, unAuthetticatedAxios } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../../../../utils/appRoutes";
import { CLIENT, ENDUSER } from "../../../../utils/constants";
import { useSelector } from "react-redux";

export const useHandleLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const linkToRedirectTo = useSelector((state) => state.mainPageSliceReducer.linkToRedirectTo);

  const handleLogin = async (formData) => {
    try {
      setLoading(true);
      setError(null);
      setData(null);
      const result = await unAuthetticatedAxios.post(apiRoutes.login, formData);
      if (result) {
        setLoading(false);
        localStorage.setItem("token", result.data.accessToken);
        setData(result.data);
        switch (result.data.userType) {
          case ENDUSER:
            navigate(linkToRedirectTo || appRoutes.endUserGlbs);
            break;
          case CLIENT:
            navigate(linkToRedirectTo || appRoutes.uploadGlb);
            break;
          default:
            break;
        }
      }
    } catch (e) {
      const error = e.response.data;
      setLoading(false);
      if (Array.isArray(error)) {
        setError(error[0].message);
      }
      return setError(error.message);
    }
  };

  return { loading, error, data, handleLogin };
};
