import { createTheme } from "@mui/material/styles";

const xlg = "320px";
const lg = "425px";
const md = "600px";
const xls = "500px";
const sm = "400px";

const mq = (size, styles) => {
  return {
    [`@media (max-width: ${size})`]: styles,
  };
};

export const defaultTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#74F751 !important",
    },
    secondary: {
      main: "#00000080 !important",
    },
    warning: {
      main: "#FFE347 !important",
    },
    error: {
      main: "#AB4E68  !important",
      light: "#A7687A  !important",
      dark: "#AD3355  !important",
    },
    info: {
      main: "#313FEF  !important",
      light: "#7D86EE !important",
      dark: "#7D86EE !important",
    },
    success: {
      main: "#4ED326  !important",
      light: "#7AC962 !important",
      dark: "#2D970D !important",
    },
    danger: {
      main: "#4AA4F8 !important",
    },
    dark: {
      main: "#000000 !important",
    },
    light: {
      main: "#ffffff !important",
    },
  },
  typography: {
    styleOverrides: {
      root: {
        fontFamily: `sfProDisplayMedium`,
      },
    },
    h1: {
      fontSize: "28.37px",
      fontFamily: `sfProDisplayMedium`,

      ...mq(lg, {
        fontSize: "24px",
      }),
    },
    h2: {
      fontSize: "25.22px",
      fontFamily: `sfProDisplayMedium`,
      ...mq(lg, {
        fontSize: "29px",
      }),
    },
    h3: {
      fontSize: "22.42px",
      fontFamily: `sfProDisplayMedium`,
      ...mq(lg, {
        fontSize: "19px",
      }),
    },
    h4: {
      fontSize: "19.93px",
      fontFamily: `sfProDisplayMedium`,

      ...mq(lg, {
        fontSize: "17px",
      }),
      ...mq(md, {
        fontSize: "16px",
      }),
      ...mq(xls, {
        fontSize: "14px",
      }),
    },
    h5: {
      fontSize: "17.72px",
      fontFamily: `sfProDisplayMedium`,

      ...mq(lg, {
        fontSize: "16px",
      }),
    },
    h6: {
      fontSize: "16px",
      fontFamily: `sfProDisplayMedium`,

      ...mq(lg, {
        fontSize: "14px",
      }),
      ...mq(xls, {
        fontSize: "12px",
      }),
      ...mq(sm, {
        fontSize: "10px",
      }),
    },
    body2: {
      fontSize: "14px",
      fontFamily: `sfProDisplayMedium`,
      ...mq(lg, {
        fontSize: "12px",
      }),
      ...mq(xls, {
        fontSize: "10px",
      }),
      ...mq(xls, {
        fontSize: "8px",
      }),
      variants: [
        {
          props: { about: "center" },
          style: {
            display: "flex",
            "& .css-1iqhs94-MuiTypography-root": {
              textAlign: "center",
            },
          },
        },
      ],
    },
    button: {
      fontSize: "16px",
      fontFamily: `sfProDisplayMedium`,
      ...mq(lg, {
        fontSize: "14px",
      }),
      ...mq(xls, {
        fontSize: "12px",
      }),
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          placeItems: "center",
          borderRadius: "25px",
          textTransform: "none ",
          boxShadow: "none",
          textAlign: "center",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
      variants: [
        {
          props: { size: "small" },
          style: {
            height: "40px",
          },
        },
        {
          props: { about: "popOverBtn" },
          style: {
            borderRadius: "8px !important",
            height: "45px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 !important",
            minWidth: "45px",
            maxWidth: "45px",
            margin: 0,
            ...mq(xlg, {
              height: "40px",
              minWidth: "40px",
              maxWidth: "40px",
            }),
            ...mq(md, {
              height: "38px",
              minWidth: "38px",
              maxWidth: "38px",
            }),
            "& .css-1273ajo-MuiButtonBase-root-MuiButton-root": {
              minWidth: "100%",
              maxWidth: "100%",
            },
          },
        },
        {
          props: { color: "success" },
          style: {
            color: "white",
            backgroundColor: "#55C25C",
          },
        },
        {
          props: { about: "purple" },
          style: {
            color: "white",
            backgroundColor: "#4AA4F8",
            ...mq(lg, {
              fontSize: "10px",
            }),
            transition: "all 0.3 ease-in-out",
            "&:hover": {
              backgroundColor: "#e800b6d4",
            },
          },
        },
        {
          props: { about: "light" },
          style: {
            color: "black",
            backgroundColor: "#ffffff",
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff !important",
          borderRadius: "25px !important",
          paddingLeft: "30px",
          border: "none",
          "& input": {
            fontFamily: "sfProDisplayMedium",
          },
          "& fieldset": {
            border: "none",
          },
        },
      },
    },
    MuiPopover: {
      variants: [
        {
          props: { about: "muiPaperPopover" },
          style: {
            "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
              borderRadius: "16px",
              backgroundColor: "transparent !important",
              ...mq(xlg, {
                borderRadius: "14px",
              }),
              ...mq(lg, {
                borderRadius: "13px",
              }),
              ...mq(md, {
                borderRadius: "10px",
              }),
            },
          },
        },
        {
          props: { about: "popOverWithInput" },
          style: {
            overflow: "hidden",
            padding: 0,
            "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
              borderRadius: "8px",
              width: "316px",
              padding: "8px",
              mixBlendMode: "color-dodge",
              backdropFilter: "blur(34px)",
              webkitBackdropFilter: "blur(34px)",
              backgroundColor: "rgba(255, 255, 255, .5) !important",
              background: "transparent",
              ...mq(xlg, {
                width: "300px",
              }),
              ...mq(lg, {
                width: "280px",
              }),
              ...mq(xls, {
                width: "250px",
              }),
              ...mq(sm, {
                width: "200px",
              }),
              "& .formSave": {
                width: "100%",
                maxHeight: "45px",
                display: "flex",
                padding: 0,
                alignItems: "center",
                gap: "15px",
                background: "transparent",
                ...mq(xlg, {
                  maxHeight: "40px",
                  gap: "10px",
                }),
                ...mq(md, {
                  maxHeight: "38px",
                  gap: "8px",
                }),
              },
            },
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "25px",
          textTransform: "none ",
          boxShadow: "none",
          textAlign: "center",
          maxHeight: "50px",
          "&:hover": {
            boxShadow: "none",
          },
          ...mq(xlg, {
            maxHeight: "40px",
          }),
          ...mq(md, {
            maxHeight: "35px",
          }),
        },
      },
      variants: [
        {
          props: { size: "search" },
          style: {
            backgroundColor: "white",
            "& .MuiInputBase-root": {
              backgroundColor: "white !important",
              borderRadius: "25px !important",
            },
            "& .css-1rvql17-MuiInputBase-root-MuiFilledInput-root": {
              backgroundColor: "white !important",
              borderRadius: "25px !important",
            },
          },
        },
        {
          props: { about: "small" },
          style: {
            backgroundColor: "white",
            "& input": {
              padding: "0 0px 0px 20px",
            },
            "& input::placeholder": {
              color: "#000",
              opacity: 0.5,
            },
          },
        },
        {
          props: { about: "popOpverInput" },
          style: {
            backgroundColor: "white",
            flexGrow: 1,
            borderRadius: "8px",
            height: "45px",
            padding: 0,
            display: "flex",
            ...mq(xlg, {
              height: "40px",
            }),
            ...mq(md, {
              height: "38px",
            }),
            "& input": {
              padding: "0 5px 0 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              borderRadius: "8px",
              height: "45px",
              fontSize: ".8rem",
              background: "white",
              ...mq(xlg, {
                height: "40px",
              }),
              ...mq(md, {
                height: "38px",
              }),
            },
            "& input::placeholder": {
              color: "rgba(0,0,0,.7)",
              fontSize: ".7rem",
            },
            "& .css-1uk2hkq-MuiInputBase-root-MuiOutlinedInput-root": {
              backgroundColor: "transparent !important",
              borderRadius: "inherit",
              padding: 0,
              margin: 0,
            },
          },
        },
        {
          props: { about: "loginput" },
          style: {
            backgroundColor: "#F0EEFE !important",
            borderRadius: "25px",
            height: "45px",
            flexGrow: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "space-between",
            ...mq(xlg, {
              height: "40px",
            }),
            ...mq(md, {
              height: "35px",
            }),
            ...mq(xls, {
              height: "30px",
            }),
            "& .MuiInputBase-root": {
              backgroundColor: "#F0EEFE !important",
            },
            "& input": {
              padding: "0px",
              backgroundColor: "#F0EEFE !important",
            },
            "& .css-1lkr5rz-MuiInputBase-input-MuiOutlinedInput-input, .css-7053f3-MuiInputBase-input-MuiOutlinedInput-input":
              {
                color: "rgba(73, 54, 162, 0.6)",
                "&::placeholder": {
                  fontSize: "16px",
                  ...mq(md, {
                    fontSize: "12px",
                  }),
                  color: "rgba(73, 54, 162, 0.6)",
                },
              },
            "& .css-7053f3-MuiInputBase-input-MuiOutlinedInput-input": {
              "&::placeholder": {
                fontSize: "18px",
                ...mq(md, {
                  fontSize: "16px",
                }),
              },
            },
            "& .css-18us4qe-MuiInputBase-root-MuiOutlinedInput-root": {
              backgroundColor: "transparent !important",
            },
            "& .css-1uk2hkq-MuiInputBase-root-MuiOutlinedInput-root": {
              backgroundColor: "transparent !important",
            },
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          minHeight: "unset",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          paddingTop: "10px",
          paddingBottom: "10px",
          "& .Mui-selected": {
            color: "#ffffff",
            backgroundColor: "#070082",
            "&:hover": {
              backgroundColor: "#332BC8",
            },
            "& .MuiListItemIcon-root": {
              color: "#ffffff",
            },
          },
          "&:hover": {
            backgroundColor: "#070082",
            color: "#ffffff",
            "& .MuiListItemIcon-root": {
              color: "#ffffff",
            },
          },
        },
      },
    },
    MuiDialog: {
      variants: [
        {
          props: { about: "uploadglb" },
          style: {
            "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
              borderRadius: "10px !important",
              backgroundColor: "#F0EEFE !important",
            },
            "& .css-qfso29-MuiTypography-root-MuiDialogContentText-root": {
              color: "black",
              textAlign: "left",
            },
            "& .css-draqkd-MuiTypography-root-MuiDialogTitle-root": {
              textAlign: "left",
            },
          },
        },
        {
          props: { about: "preview" },
          style: {
            "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
              borderRadius: "10px !important",
              backgroundColor: "#F0EEFE !important",
              minHeight: "60vh",
              maxHeight: "fit-content",
              minWidth: "40vw",
              padding: "10px",
              overFlow: "hidden",
            },
            "& .css-qfso29-MuiTypography-root-MuiDialogContentText-root": {
              color: "black",
              textAlign: "left",
            },
            "& .css-draqkd-MuiTypography-root-MuiDialogTitle-root": {
              textAlign: "left",
            },
            "& .css-hlj6pa-MuiDialogActions-root": {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            },
            "& .css-161g7ga-MuiDialogContent-root": {
              borderRadius: "10px",
              backgroundColor: "white",
              overFlow: "hidden",
            },
            "& .css-1j861ql": {
              width: "100%",
              minHeight: "60vh",
              margin: 0,
            },
            "& .css-1wmld2k-MuiPaper-root-MuiAlert-root": {
              display: "flex",
              alignItems: "center",
              ...mq(xlg, {
                fontSize: "12px",
              }),
              ...mq(lg, {
                fontSize: "11px",
              }),
              ...mq(md, {
                fontSize: "10px",
              }),
              ...mq(xls, {
                fontSize: "8px",
              }),
            },
          },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.5em",
          lineHeight: `${31.2 / 24}`,
          textAlign: "center",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: "1em",
          lineHeight: 1.3,
          textAlign: "center",
          fontFamily: `sfProDisplayMedium`,
          color: "#000000",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0px",
          marginRight: "0px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: "#F0F0F0",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "50px !important",
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { severity: "error" },
          style: {
            background: "#E45C76",
            color: "#fff",
            "& .MuiAlert-icon": {
              color: "white !important",
            },
          },
        },
        {
          props: { severity: "info" },
          style: {
            background: "#A49BD0",
            color: "#fff",
            "& .MuiAlert-icon": {
              color: "white !important",
            },
          },
        },
        {
          props: { severity: "success" },
          style: {
            background: "#55C25C",
            color: "#fff",
            "& .MuiAlert-icon": {
              color: "white !important",
            },
          },
        },
        {
          props: { about: "fill-width" },
          style: {
            width: "100%",
          },
        },
      ],
    },
    MuiSkeleton: {
      variants: [
        {
          props: { about: "rounded" },
          style: {
            width: "100%",
            borderRadius: "100px",
          },
        },
      ],
    },
  },
  spacing: 8,
});
