import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export const createOrbitControls = (camera, canvas) => {
    const controls = new OrbitControls(camera, canvas);

    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.rotateSpeed = 0.5;
    controls.zoomSpeed = 1.0;
    controls.panSpeed = 0.8;
    controls.enableKeys = false;
    controls.maxDistance = 500;
    controls.minDistance = 0.1;
    // controls.maxPolarAngle = Math.PI / 2.2;
    controls.minPolarAngle = 0;
    controls.target.set(0, 11, 0);

    return controls;
};