import React, { useRef, useCallback } from "react";
import { Button, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import styles from "./fileUploadComponentStyle.module.scss";

export default function FileUploadComponent(props) {
  const {
    handleRemoveGlb,
    handleGlbChange,
    errors,
    touched,
    title,
    subTitle,
    fileTypes,
    mimeTypes,
    maxFileSize,
    dropTitle,
    fileUrl,
    uploadSvg,
    fileType
  } = props;
  const ref = useRef(null);
  const handleClickUpload = () => ref.current.click();
  const onDrop = useCallback(
    (acceptedFiles) => {
      handleGlbChange(acceptedFiles);
    },
    [handleGlbChange]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: mimeTypes,
  });
  return (
    <div className={styles.fileUploadComponent}>
      <div className="labelBox">
        <Typography variant="body2" className="label">
          {title}&nbsp;&nbsp;
        </Typography>
        <div className="opacity">
          <Typography variant="body2" className="label" color="secondary">
            {subTitle}
          </Typography>
          <Typography variant="body2" className="label purple" about="purple">
            *
          </Typography>
        </div>
      </div>
      <div className="glbupload">
        <div {...getRootProps({ className: "dropzone" })}>
          <div className="imageBox">
            <img
              src={uploadSvg}
              alt="icon"
              className="icon"
            />
          </div>
          <input
            {...getInputProps()}
            type="file"
            hidden
            ref={ref}
          />
          <div className="instructions">
            <Typography variant="h4" className="title">
              {dropTitle}
            </Typography>
            <Typography variant="body2" className="label" color="secondary">
              1. Drag 'n' drop some files here, or click to select files
            </Typography>
            <Typography variant="body2" className="label" color="secondary">
              2. The file must be of type {fileTypes}
            </Typography>
            <Typography variant="body2" className="label" color="secondary">
              3. The file must not exceed {maxFileSize}MB
            </Typography>
            <Typography variant="body2" className="label" color="secondary">
              4. The file should not infringe copyright
            </Typography>
          </div>
        </div>
        {fileUrl && (
          <SINGLE_FILE
            filename={fileUrl}
            handleClickUpload={handleClickUpload}
            handleRemoveGlb={handleRemoveGlb}
            title={fileType}
            fileSvg={uploadSvg}
          ></SINGLE_FILE>
        )}
      </div>
      {errors && touched ? (
        <Typography variant="body2" className="label" color="red">
          {errors}
        </Typography>
      ) : null}
    </div>
  );
}
function SINGLE_FILE({ filename, handleClickUpload, handleRemoveGlb, title, fileSvg }) {
  return (
    <div className="bottomBox">
      <div className="imageBox sizeHeightChilds">
        <img src={fileSvg} alt="icon" className="icon" />
      </div>
      <div className="name sizeHeightChilds">{filename}</div>
      <Button
        variant="contained"
        className="btn update sizeHeightChilds"
        color="light"
        onClick={handleClickUpload}
      >
        Update {title}
      </Button>
      <Button
        className="btn sizeHeightChilds"
        variant="about"
        about="purple"
        onClick={handleRemoveGlb}
      >
        Remove {title}
      </Button>
    </div>
  );
}
