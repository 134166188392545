import React, { useContext, useState } from 'react'
import { FeatureDisplayContext } from '../featureDisplay/FeatureDisplay'
import styles from "./Feature.module.scss"

const FeatureContext = React.createContext()

export default function Feature() {
    const featureDisplayContext = useContext(FeatureDisplayContext)
    const [activeFeature, setActiveFeature] = useState(featureDisplayContext.features[0])
    return (
        <>
            <FeatureContext.Provider value={{ activeFeature, setActiveFeature }}>
                <div className="flex" id={styles.Feature} style={{
                    flexDirection: featureDisplayContext.reverse ? "row-reverse" : "row"
                }}>
                    < div className="features" >
                        <div className="feature-list">
                            {featureDisplayContext.features.map((feature, index) => {
                                return <SingleFeature key={index} feature={feature} />
                            })}
                        </div>
                        <div className="feature-desc" style={{
                            textAlign: featureDisplayContext.reverse ? "right" : "left"
                        }}>
                            {activeFeature && activeFeature.description}
                        </div>
                    </div>
                    <div className="feature-video">
                        {activeFeature && activeFeature.image && <img src={activeFeature.image} alt="" />}
                    </div>
                </div >
            </FeatureContext.Provider >
        </>
    )
}


const SingleFeature = ({ feature }) => {
    const { activeFeature, setActiveFeature } = useContext(FeatureContext)
    const featureDisplayContext = useContext(FeatureDisplayContext)
    return (<>
        <div onClick={() => { setActiveFeature(feature) }} className={`${activeFeature.text === feature.text && "active"} single-feature`} style={{
            textAlign: featureDisplayContext.reverse ? "right" : "left"
        }}>{feature.text}</div>
    </>)
}