import { useLazyQuery, useMutation } from '@apollo/client';
import { useState, useRef } from 'react';
import { INSERT_TEXTURE } from '../../../../graphql/insertTexture.mutation';
import { useFileUpload } from '../../../../hooks/UseFileUpload';
import { APP_CONTAINER_CLASS } from '../../../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from '../../../../utils/appRoutes';
import { SINGLE_TEXTURE_INFO } from '../../../../graphql/singleTextureInfo.query';
import { UPDATE_TEXTURE } from '../../../../graphql/updateTexture.mutation';

export const useGlbUpload = (edit) => {
    const [insertTexture, { data, loading }] = useMutation(INSERT_TEXTURE)
    const { loading: uploadImageLoading, data: uploadImageData, handleFileUplaod: uploadImage } = useFileUpload(true)
    const { loading: deleteImageLoading, data: deleteImageData, handleFileDelete: deleteImage } = useFileUpload(true)
    const { id } = useParams()
    const [textureInformation, { data: glbData, loading: glbLoading }] = useLazyQuery(SINGLE_TEXTURE_INFO, { fetchPolicy: "no-cache" })
    const [updateTexture, { loading: updateGlbLoading, data: updateGlbData }] = useMutation(UPDATE_TEXTURE)
    const [hookError, setHookError] = useState(null)

    const navigate = useNavigate()
    const requestRef = useRef()

    const fileHandling = async (textureImage, textureInfo) => {
        const handleplaceholderImage = async () => {
            if (edit) {
                if (typeof textureImage === "string") {
                    return {
                        key: textureImage.split(process.env.REACT_APP_CLOUDFRONT_URL).pop(),
                        url: textureImage
                    }
                }
                await deleteImage(textureInfo.file_key)
                return await uploadImage(textureImage, "texture")
            }
            return await uploadImage(textureImage, "texture")
        }

        const textureImageUrl = await handleplaceholderImage()
        return { textureImageUrl }
    }
    const handleGlbUpload = async (data) => {
        try {
            if (requestRef.current) return null
            requestRef.current = true
            document.querySelector(APP_CONTAINER_CLASS).scrollTo({ top: 0, behavior: 'smooth' });
            setHookError(null)
            const { textureName, associatedGlb, productRarity, texture } = data
            const glbCurrentInfo = edit && await textureInformation({ variables: { id } })
            const { textureImageUrl } = await fileHandling(texture, edit && glbCurrentInfo?.data?.users_textures_by_pk)
            if (textureImageUrl) {
                if (edit) {
                    await updateTexture({
                        variables: {
                            file_key: textureImageUrl.key,
                            file_url: textureImageUrl.url,
                            rarity: productRarity,
                            associated_glb: associatedGlb,
                            texture_name: textureName,
                            id
                        }
                    })
                }
                else {
                    await insertTexture({
                        variables: {
                            file_key: textureImageUrl.key,
                            file_url: textureImageUrl.url,
                            rarity: productRarity,
                            associated_glb: associatedGlb,
                            texture_name: textureName,
                        }
                    })
                }
                navigate(appRoutes.manageTextures)
                requestRef.current = false
            }
        } catch (error) {
            console.log(error)
            requestRef.current = false
            setHookError("an error occured when upload the texture file")
        }
    }


    const handleLoadingMessage = () => {
        if (loading) {
            return "Creating texture file"
        }
        if (uploadImageLoading) {
            return "uploading texture image"
        }
        if (deleteImageLoading) {
            return "deleting old texture image"
        }
        if (updateGlbLoading) {
            return "Updating texture info"
        }
        if (glbLoading) {
            return "loading texture info"
        }
    }

    const loadingState = handleLoadingMessage()
    const errorState = hookError
    const dataState = data && uploadImageData && deleteImageData && updateGlbData && glbData

    return { loadingState, errorState, dataState, handleGlbUpload }
}