import React from "react";

import styles from "./emptyGlb.module.scss";
import { Typography } from "@mui/material";

export default function NotFound({ body, title }) {

  return (
    <div className={`${styles.emptyGlb} customize-empty-glb`}>
      <img className="bg_empty" src="/images/404.svg" alt="not found" />
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body2" color="secondary" className="details" align="center">
        {body}
      </Typography>
    </div>
  );
}
