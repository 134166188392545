import React from "react";
import styles from "./ArticView.module.scss";

export default function ImageComponent({ background }) {
  return (
    <div id={styles.articView} style={{ backgroundImage: `url("${background}")` }}>
      <div> </div>
    </div>
  );
}
