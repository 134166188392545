import React, { useState } from "react";
import FormUpload from "./components/FormUpload/FormUpload";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import FormLayout from "../../components/dashboardLayout/FormLayout.layout";

export default function UserProfile() {

  const [placeholderPreview, setPlaceholderPreview] = useState("");

  return (
      <DashboardLayout>
        <FormLayout
          title="Edit Your Profile"
          imagePreview={placeholderPreview}
          imagePreviewTitle="Preview Profile"
          userProfile = {true}
        >
           <FormUpload
              setPlaceholderPreview={setPlaceholderPreview}
            />
        </FormLayout>
      </DashboardLayout>
  );
}
