import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styles from "./previewPopup.module.scss";
import { loadCdn } from "../../utils/fileFetch";
import HDRIViewer from "../HdriViewer/HDRIViewer";

export default function PreviewPopup(props) {
  const { showPreviewPopUp, setShowPreviewPopUp } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setShowPreviewPopUp({ permition: false, image: "" });
  };
  return (
    <div className={styles.previewPopup}>
      <Dialog fullScreen={fullScreen} open={showPreviewPopUp.permition} onClose={handleClose} aria-labelledby="responsive-dialog-title" about="preview">
        <DialogActions>
          <DialogTitle id="responsive-dialog-title">
            {props.glb && "Glb Preview"}
            {props.texture && "Texture Preview"}
            {props.hdri && "Hdri Preview"}
            {props.mod && "Mod Preview"}
          </DialogTitle>
          <Button onClick={handleClose} variant="about" about="light">
            Cancel
          </Button>
        </DialogActions>
        <DialogContent sx={{ padding: "0px" }}>
          {(props.glb || props.mod) && (
            <model-viewer style={{ width: "100%", height: "500px" }} alt="glb preview" src={showPreviewPopUp.image} ar environment-image="./hdri/blue_photo_studio_1k.hdr" shadow-intensity="1" camera-controls touch-action="pan-y"></model-viewer>
          )}
          {props.texture && (
            <div
              className="texture"
              style={{
                backgroundColor: "#fff",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "100%",
                height: "500px",
                backgroundImage: `url(${showPreviewPopUp.image})`,
              }}
            ></div>
          )}
          {props.hdri && (
            <div style={{ width: "100%", height: "500px" }}>
              <HDRIViewer hdr={showPreviewPopUp.image} />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
