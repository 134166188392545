import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
// import { Vector3, Box3 } from 'three';
import { handleLookAtMesh } from '../customization/handleLookAtMesh';

export const handleGlb = (glbPath, scene, LoadingManager, canvas, renderer, camera, controls) => {
    const glbLoader = new GLTFLoader(LoadingManager)
    const dracoLoader = new DRACOLoader(LoadingManager);

    dracoLoader.setDecoderPath("https://threejs.org/examples/jsm/libs/draco/");
    dracoLoader.preload();
    glbLoader.setDRACOLoader(dracoLoader);
    const meshOptLoader = glbLoader;
    meshOptLoader.setMeshoptDecoder(MeshoptDecoder)
    const ktx2Loader = new KTX2Loader();
    ktx2Loader.setTranscoderPath("https://threejs.org/examples/jsm/libs/basis/");
    ktx2Loader.detectSupport(renderer);
    meshOptLoader.setKTX2Loader(ktx2Loader);
    glbLoader.load(glbPath, (glb) => {
        const model = glb.scene
        scene.add(model);
        handleLookAtMesh(camera, model, controls)
    });

}