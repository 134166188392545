import { gql } from '@apollo/client';

export const INSERT_TEXTURE = gql`
mutation INSERT_TEXTURE(
$file_key: String!,
$file_url: String!,
$rarity: Int!,
$associated_glb: uuid!,
$texture_name: String!
){
  insert_users_textures_one(object: {
    file_key: $file_key,
    file_url: $file_url,
    rarity: $rarity,
    associated_glb: $associated_glb,
    texture_name: $texture_name
      }) {
    id
  }
}
`