import { useState } from "react"
import { apiRoutes, unAuthetticatedAxios } from "../../../../utils/api"

export const useHandleSignUp = () => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [data, setData] = useState(null)

    const handleSignup = async (formData) => {
        try {
            setLoading(true)
            setError(null)
            setData(null)
            const result = await unAuthetticatedAxios.post(apiRoutes.signup, formData)
            if (result) {
                setLoading(false)
                setData(result.data)
            }
        } catch (e) {
            const error = e.response.data
            setLoading(false)
            if (Array.isArray(error)) {
                setError(error[0].message)
            }
            return setError(error.message)
        }
    }

    return { loading, error, data, handleSignup }
}