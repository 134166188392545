import { gql } from "@apollo/client";

export const UPDATE_TEXTURE = gql`
mutation UPDATE_TEXTURE(
$file_key: String!,
$file_url: String!,
$rarity: Int!,
$associated_glb: uuid!,
$texture_name: String!
$id:uuid!
) {
  update_users_textures_by_pk(pk_columns: {id: $id}, _set: {
    file_key: $file_key,
    file_url: $file_url,
    rarity: $rarity,
    associated_glb: $associated_glb,
    texture_name: $texture_name
  }) {
    id
  }
}

`