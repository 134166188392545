import { useState } from 'react';
import { useFileUpload } from '../../../../hooks/UseFileUpload';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PROFILE, UPDATE_USER_PROFILE_WITHOUT_IMAGE } from '../../../../graphql/updateUserProfile.mutation';
import { apiRoutes, authetictedAxios, } from '../../../../utils/api';

export const useHandleUserProfile = () => {

    const { loading: loadingProfileUpload, error: errorProfileUpload, data: dataProfileUpload, handleFileUplaod } = useFileUpload(true);
    const [updateUserProfile, { loading: loadingUpdateProfile, error: errorUpdateProfile, data: dataUpdateProfile }] = useMutation(UPDATE_USER_PROFILE)
    const [updateTextOnly, { loading: loadingUpdateTextOnly, error: errorUpdateTextOnly, data: dataUpdateTextOnly }] = useMutation(UPDATE_USER_PROFILE_WITHOUT_IMAGE)
    const [processError, setProcessError] = useState(false)
    const [processLoading, setProcessLoading] = useState(false)

    const handler = async (values) => {
        try {
            setProcessError(false)
            const { username, industry, location, description, placeholderImage } = values;
            const variables = {
                bio: description,
                industry: industry,
                username: username,
                location: location
            }
            if (placeholderImage instanceof File) {
                const fileUpload = await handleFileUplaod(placeholderImage, "texture");
                if (fileUpload) {
                    const updateProfile = await updateUserProfile({
                        variables: {
                            ...variables,
                            user_profile_picture: fileUpload.url,
                            profile_key: fileUpload.key,
                        }
                    })
                    if (updateProfile) {
                        return updateProfile
                    }
                }
            }
            else {
                const updateProfile = await updateTextOnly({
                    variables: {
                        ...variables,
                        user_profile_picture: values.fileUrl,
                        profile_key: values.fileKey,
                        id: values.id
                    }
                })
                if (updateProfile) {
                    return updateProfile
                }
            }
        } catch (error) {
            console.log("error in updating profile", error)
            setProcessError(true)
        }
    }

    const doUserProfileExist = async (username) => {
        try {
            setProcessError(false)
            setProcessLoading(true)
            const response = await authetictedAxios().post(apiRoutes.doesUsernameExist, { username })
            if (response) {
                setProcessLoading(false)
                return response.data.result
            }
        } catch (error) {
            setProcessLoading(false)
            setProcessError(true)
        }
    }

    const loadingMessage = () => {
        if (processLoading){
            return "Loading..."
        }
        if (loadingProfileUpload) {
            return "Uploading Profile Image..."
        }
        if (loadingUpdateProfile || loadingUpdateTextOnly) {
            return "Updating Profile..."
        }
    }
    return { loading: loadingMessage(), error: (errorProfileUpload || errorUpdateProfile || processError || errorUpdateTextOnly), data: ((dataProfileUpload && dataUpdateProfile) || dataUpdateTextOnly), handler, doUserProfileExist }
}