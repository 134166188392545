import { gql } from "@apollo/client";

export const SINGLE_HDRI_TEXTURE_INFO = gql`
query SINGLE_HDRI_TEXTURE_INFO($id: uuid!) {
  users_glbs_by_pk(id: $id) {
   users_hdris {
      file_url
      hdri_name
    }
  }
}

`