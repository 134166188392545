import React, { useEffect } from "react";
import { fabric } from "fabric";
import { fabricCanvasName } from "../../../../utils/constants";
import { handleFabricCanvas } from "../configurator/events/useFabricCanvas";

function CanvasComponent({ texture }) {
  useEffect(() => {
    let canvas;
    fetch("./Mesh004_2.svg")
      .then((response) => response.text())
      .then((svgString) => {
        let parser = new DOMParser();
        let doc = parser.parseFromString(svgString, "image/svg+xml");
        let svgElement = doc.documentElement;

        let width = svgElement.getAttribute("width");
        let height = svgElement.getAttribute("height");
        const aspectRatio = width / height;
        width = 500;
        height = width / aspectRatio;
        const canvasEl = document.getElementById(fabricCanvasName);
        canvasEl.setAttribute("width", width);
        canvasEl.setAttribute("height", height);
        canvas = new fabric.Canvas(fabricCanvasName);
        fabric.Image.fromURL("./imageTexture.png", function (img) {
          let scaleX = canvas.width / img.width;
          let scaleY = canvas.height / img.height;
          img.set({
            left: (canvas.width - img.width * scaleX) / 2, // centering img
            top: (canvas.height - img.height * scaleY) / 2, // centering img
            scaleX: scaleX,
            scaleY: scaleY,
            lockUniScaling: true,
            globalCompositeOperation: "source-atop",
          });

          fabric.loadSVGFromURL("./Mesh004_2.svg", function (objects, options) {
            let svg = fabric.util.groupSVGElements(objects, options);
            svg.scaleToWidth(canvas.width);
            svg.scaleToHeight(canvas.height);
            svg.set({
              left: (canvas.width - svg.width * svg.scaleX) / 2, // centering svg
              top: (canvas.height - svg.height * svg.scaleY) / 2, // centering svg
            });

            canvas.add(svg);
            canvas.add(img);

            canvas.renderAll();
            canvas.on("object:modified", function (options) {
              handleFabricCanvas(fabricCanvasName);
            });
            handleFabricCanvas(fabricCanvasName);
          });
        });
      })
      .catch((error) => console.error(error));

    // cleanup function
    return () => {
      canvas?.dispose();
    };
  }, []);

  return (
    <div>
      <canvas id={fabricCanvasName} />
    </div>
  );
}

export default CanvasComponent;
