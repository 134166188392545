import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

export default function AutheticatedGraphql({ children }) {
  const link = from([
    new HttpLink({
      uri: `${process.env.REACT_APP_HASURA_URL}`,
      credentials: "include",
    }),
  ]);
  const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    const includeToken = () => {
      if (token) {
        return { authorization: `Bearer ${token}` };
      } else {
        return null;
      }
    };
    return {
      headers: {
        ...headers,
        ...includeToken(),
      },
    };
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(link),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
