import { gql } from '@apollo/client';

export const INSERT_HDRI = gql`
mutation INSERT_HDRI(
$file_key: String!,
$file_url: String!,
$rarity: Int!,
$associated_glb: uuid!,
$hdri_name: String!
){
  insert_users_hdri_one(object: {
    file_key: $file_key,
    file_url: $file_url,
    rarity: $rarity,
    associated_glb: $associated_glb,
    hdri_name: $hdri_name
      }) {
    id
  }
}
`