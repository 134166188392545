import { useState, useEffect } from "react"
import { apiRoutes, authetictedAxios, unAuthetticatedAxios } from "../../../../utils/api"
import { imagesLocalStorageKey } from "../../../../utils/constants"
import { useDispatch } from "react-redux"
import { setLoginAndSignPopup } from "../../../../redux/mainPageSlice"

export const useStableDiffusion = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [images, setImages] = useState(JSON.parse(localStorage.getItem(imagesLocalStorageKey)) || [])
    const [generartionCount, setGenerartionCount] = useState(0)

    useEffect(() => {
        localStorage.setItem(imagesLocalStorageKey, JSON.stringify(images))
    }, [images])

    const dispatch = useDispatch()
    useEffect(() => {
        if (generartionCount === 3) {
            dispatch(setLoginAndSignPopup({
                message: "Your generated images will be deleted after 15 days login or signup to access more benefits",
            }))
        }
    }, [dispatch, generartionCount])


    const handlePrompt = async (input) => {
        try {
            setLoading("Processing the prompt")
            setError(null)
            setData(null)
            const data = {
                "prompt": input,
                "steps": 30
            }
            let result
            const token = localStorage.getItem("token")
            if (token) {
                result = await authetictedAxios().post(apiRoutes.stableDiffusionAdmin, data)
            }
            else {
                result = await unAuthetticatedAxios.post(apiRoutes.stableDiffusion, data)
            }
            if (result) {
                const copyOfIamges = [...images]
                copyOfIamges.unshift({
                    image: result.data.url,
                    prompt: input,
                    url: result.data.url
                })
                setImages(copyOfIamges)
                setLoading(false)
                setData(result.data)
                setGenerartionCount(generartionCount + 1)
            }
        }
        catch (err) {
            console.log(err)
            setLoading(false)
            if (err?.response?.data?.statusCode === 429) {
                setLoginAndSignPopup({
                    message: "You have reached the limit of 20 for public generations. Please login or signup to continue"
                })
            }
            setError(err)
        }
    }

    return { loading, data, error, handlePrompt, images }

}