import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';

export const initializeComposer=(renderer,scene,camera,passes)=>{
    const composer = new EffectComposer(renderer);

    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass);
    passes.forEach((singlePass)=>{
        composer.addPass(singlePass)
    })
    return composer
}