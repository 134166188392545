import { gql } from "@apollo/client";

export const GET_USER_INFO = gql`
    query GET_USER_INFO{
    users {
        first_name
        last_name
        user_type
    }
    }
`