import * as THREE from "three"
export function handleLookAtMesh(camera,selectedMesh,controls){
    const offset=1.5
    const box = new THREE.Box3().setFromObject(selectedMesh);
    const size = box.getSize(new THREE.Vector3());
    const center = box.getCenter(new THREE.Vector3());

    const maxSize = Math.max(size.x, size.y, size.z);
    const aspectRatio = camera.aspect;
    const verticalFovRadians = (Math.PI * camera.fov) / 180;
    const horizontalFovRadians = 2 * Math.atan(Math.tan(verticalFovRadians / 2) * aspectRatio);

    const fitHeightDistance = (maxSize / 2) / Math.tan(verticalFovRadians / 2);
    const fitWidthDistance = (maxSize / 2) / Math.tan(horizontalFovRadians / 2);
    const distance = offset * Math.max(fitHeightDistance, fitWidthDistance);

    const direction = new THREE.Vector3().subVectors(camera.position, center).normalize().multiplyScalar(distance);

    camera.position.copy(center).add(direction);
    controls.target.copy(center);
    controls.update();
}