import { gql } from "@apollo/client";

export const UPDATE_USER_PROFILE = gql`
mutation UPDATE_USER_PROFILE(
$bio: String!
$industry: Int!
$user_profile_picture: String!
$username: String!
$profile_key: String!
$location: String!
) {
  delete_users_general_information(where: {}) {
    affected_rows
  }
  insert_users_general_information_one(object: {bio: $bio, industry: $industry, user_profile_picture: $user_profile_picture, username: $username,profile_key: $profile_key,location: $location}) {
    id
  }
}
`

export const UPDATE_USER_PROFILE_WITHOUT_IMAGE = gql`
mutation UPDATE_USER_PROFILE_WITHOUT_IMAGE(
$bio: String!
$industry: Int!
$location: String!
$profile_key: String!
$user_profile_picture: String!
$username: String!
$id:Int!
) {
  update_users_general_information_by_pk(
    pk_columns: { id: $id }
    _set: {
      bio: $bio
      industry: $industry
      location: $location
      profile_key: $profile_key
      user_profile_picture: $user_profile_picture
      username: $username
    }
  ) {
    id
  }
}
`