import { gql } from "@apollo/client";

export const SINGLE_HDRI_INFO = gql`
query SINGLE_HDRI_INFO($id:uuid!) {
  users_hdri_by_pk(id: $id){
    associated_glb
    created_at
    file_key
    file_url
    id
    rarity
    users_glb {
      id
    }
    hdri_name
  }
}
`