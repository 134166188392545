import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { DELETE_USER_GLB } from '../../graphql/deleteuserGlb.mutation';

export const useUserGlbDelete = (key) => {
    const [deleteGlbHasura, { data, loading, error }] = useMutation(DELETE_USER_GLB)
    const [hookError, setHookError] = useState(null)

    const handleDeleteGlb = async () => {
        try {
            setHookError(null)
            await deleteGlbHasura({ variables: { id: key } })
        } catch (error) {
            console.log(error)
            setHookError("an error occured when upload the glb file")
        }
    }

    const loadingState = loading 
    const errorState = error || hookError
    const dataState = data 

    return { loadingState, errorState, dataState, handleDelete: handleDeleteGlb }
}