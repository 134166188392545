import * as THREE from 'three';
export const createRenderer = (canvas,width,height) => {
    const renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: canvas,
        physicallyCorrectLights: true,
        preserveDrawingBuffer: true
    });

    renderer.setClearColor(0x2BBFE9, 1);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.gammaFactor = 2.2;
    renderer.gammaOutput = true;
    renderer.toneMappingExposure = 1.5;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    return renderer;
};