import React from 'react'
import styles from "./Footer.module.scss"

export default function Footer() {
    return (
        <div id={styles.footer}>
            <div className="flex">
                <div className="footer-btn">
                    CONFIGURE NOW
                </div>
            </div>
            <div className="footer-links">
                <div className="links">
                    <div className="link">PRIVACY POLICY</div>
                    <div className="link">COOKIES</div>
                    <div className="link">LEGAL</div>
                    <div className="link">COOKIES SETTINGS</div>
                    <div className="link">NEWSLETTERS</div>
                </div>
            </div>
            <div className="footer-socials">
                <div className="social">
                    <img src="/images/facebook.svg" alt="" />
                    <img src="/images/twitter.svg" alt="" />
                    <img src="/images/linkedin.svg" alt="" />
                    <img src="/images/instagram.svg" alt="" />
                    <img src="/images/pinterest.svg" alt="" />
                </div>
                <div className="logo">
                    <img src="/images/logo.svg" alt="" />
                </div>
            </div>
        </div>
    )
}
