import { useState } from 'react';
import { apiRoutes, authetictedAxios } from '../utils/api';

export const useFileUpload = (throwError = false) => {
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState(null);
    const [data, setdata] = useState(null)

    const handleFileUplaod = async (file, fileType) => {
        try {
            setloading(true);
            seterror(null);
            setdata(null);
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileType", fileType);
            const result = await authetictedAxios().post(apiRoutes.fileUpload, formData);
            if (result) {
                setdata(result.data);
                setloading(false);
                return result.data;
            }
        } catch (error) {
            setloading(false)
            seterror(error.response.data.message)
            if (throwError) {
                throw new Error(error.response.data.message)
            }
        }
    }

    const handleFileDelete = async (fileKey) => {
        try {
            setloading(true);
            seterror(null);
            setdata(null);
            const data = {
                key: fileKey
            }
            const result = await authetictedAxios().post(apiRoutes.deleteFile, data);
            if (result) {
                setdata(result.data);
                setloading(false);
                return result.data;
            }
        } catch (error) {
            setloading(false)
            const e = "Error while deleting file"
            seterror(e)
            if (throwError) {
                throw new Error(e)
            }
        }
    }

    return { loading, error, data, handleFileUplaod, handleFileDelete }
}