import * as React from "react";
import styles from "./FormUpload.module.scss";
import { Button, Typography, TextField, Alert, Skeleton, Select, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { useQuery } from "@apollo/client";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  ACCEPTED_IMAGE_MB,
  ACCEPTED_IMAGE_SIZE,
} from "../../../../utils/constants";
import { useHdriUpload } from "./useHdriUpload";
import { Context } from "../../../../components/dashboardLayout/FormLayout.layout";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import CircularProgressComponent from "../../../../components/circularProgress/CircularProgress.component";
import NotFound from "../../../../components/404/404";
import FileUploadComponent from "../../../../components/FileUpload/FileUploadComponent.component";
import { LIST_OF_RARITY } from '../../../../graphql/listOfRarity.query';
import { LIST_OF_GLBS } from "../../../../graphql/listOfGlbs.query";
import { SINGLE_HDRI_INFO } from "../../../../graphql/singlehdriInfo.query";

export default function FormUpload(props) {
  const { setPlaceholderPreview, hdrPreview } = props;
  const [placeholderImageName, setPlaceholderImageName] = useState(null);

  const handleRemovePlaceholder = () => {
    setPlaceholderPreview(null);
    setPlaceholderImageName(null);
  };

  const submitBtnRef = useRef();
  const handleSubmitBtn = (e, setFieldValue, value) => {
    submitBtnRef.current.click();
  };

  const validationSchema = Yup.object().shape({
    hdriName: Yup.string().required("Hdri name is Required"),
    associatedGlb: Yup.string().required("Assoaciated glb is Required"),
    productRarity: Yup.number().required("Rarity is Required"),
    hdri: Yup.mixed()
      .required("An HDRI file is required")
      .test(
        "fileSize",
        `file size must be ${ACCEPTED_IMAGE_MB} or smaller`,
        (value) => {
          if (typeof value === "string") {
            return true;
          }
          return value && value.size <= ACCEPTED_IMAGE_SIZE;
        }
      )
  });

  // isEdit
  const { edit } = React.useContext(Context);
  const [hdriInfo, { loading, data, error }] = useLazyQuery(SINGLE_HDRI_INFO, {
    fetchPolicy: "no-cache",
  });
  const { id } = useParams();
  const defaultValues = {
    hdriName: "",
    associatedGlb: "",
    productRarity: "",
    hdri: null,
  };
  const [initialValues, setInitialValues] = useState(defaultValues);
  const handleInitialValue = async () => {
    if (edit) {
      const result = await hdriInfo({ variables: { id } });
      if (result.data) {
        const { users_hdri_by_pk: glbSingle } = result.data;
        setPlaceholderPreview(glbSingle.file_url);
        setPlaceholderImageName(
          glbSingle.file_url.split("/").pop()
        );
        return {
          hdri: glbSingle.file_url,
          hdriName: glbSingle.hdri_name,
          associatedGlb: glbSingle.users_glb.id,
          productRarity: glbSingle.rarity,
        };
      }
    }
    return defaultValues;
  };
  React.useEffect(() => {
    const _ = async () => {
      const result = await handleInitialValue();
      setInitialValues(result);
    };
    _();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    loadingState,
    errorState,
    dataState,
    handleGlbUpload: uploadHandler,
  } = useHdriUpload(edit);
  const handleSubmit = (values) => {
    uploadHandler(values);
  };
  const { loading: rarityLoading, data: rarityData, error: rarityError } = useQuery(LIST_OF_RARITY)
  const { loading: glbsLoading, data: glbsData, error: glbsError } = useQuery(LIST_OF_GLBS(""))

  return (
    <>
      {edit && loading && <CircularProgressComponent onTop={true} />}
      {edit && error && (
        <NotFound
          title="Error fetching glb info"
          body={`Issue detected: Invalid .glb ID, network error, or sign-in token. Check inputs, connectivity, or sign in again. Contact support if issues persist.`}
        />
      )}
      {((edit && data) || (!edit && initialValues)) && (
        <>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, setFieldValue }) => {
              const handlePlaceholderChange = (files) => {
                const selectedFile = files[0];
                setFieldValue("hdri", selectedFile);
                if (hdrPreview instanceof Blob) {
                  URL.revokeObjectURL(hdrPreview);
                }
                if (selectedFile) {
                  const blobUrl = URL.createObjectURL(selectedFile);
                  setPlaceholderImageName(selectedFile.name);
                  setPlaceholderPreview(blobUrl);
                } else {
                  setPlaceholderPreview(null);
                  setPlaceholderImageName(null);
                }
              };
              const imageFileProps = {
                handleRemoveGlb: handleRemovePlaceholder,
                handleGlbChange: handlePlaceholderChange,
                fileUrl: placeholderImageName,
                errors: errors.hdri,
                touched: touched.hdri,
                title: "Upload the HDRI",
                subTitle:
                  " (Upload the HDRI asset to be used)",
                fileTypes: ".hdr",
                mimeTypes: {
                  'image/x-hdr': ['.hdr'],
                  'image/vnd.radiance': ['.hdr'],
                },
                maxFileSize: 2,
                dropTitle: "Upload The HDRI for the glb",
                uploadSvg: "/images/hdriIcon.svg",
                fileType: "Hdri"
              };
              return (
                <Form className={styles.formUpload} autoComplete="off">
                  <div className="form_content">
                    {loadingState && (
                      <Alert severity="info">{loadingState}</Alert>
                    )}
                    {errorState && (
                      <Alert severity="error">
                        An error occured when trying to upload glb
                      </Alert>
                    )}
                    {dataState && (
                      <Alert severity="success">
                        hdri Uploaded sucessfully
                      </Alert>
                    )}
                    <>
                      <div className="labelBox">
                        <Typography variant="body2" className="label">
                          HDRI Name&nbsp;&nbsp;
                        </Typography>
                        <div className="opacity">
                          <Typography
                            variant="body2"
                            className="label"
                            color="secondary"
                          >
                            (add a name describing this HDRI)
                          </Typography>
                          <Typography
                            variant="body2"
                            className="label purple"
                            about="purple"
                          >
                            *
                          </Typography>
                        </div>
                      </div>
                      <TextField
                        variant="outlined"
                        classes={{ root: "input" }}
                        placeholder='Example: "Venice city"'
                        onChange={handleChange}
                        value={values.hdriName}
                        name="hdriName"
                      />
                      {errors.hdriName && touched.hdriName ? (
                        <Typography
                          variant="body2"
                          className="label"
                          color="red"
                        >
                          {errors.hdriName}
                        </Typography>
                      ) : null}
                    </>
                    <>
                      <div className="labelBox">
                        <Typography variant="body2" className="label">Choose associated glb </Typography>
                        <div className="opacity">
                          <Typography variant="body2" className="label" color='secondary'>
                            (Please specify the glb associated with this HDRI)
                          </Typography>
                          <Typography variant="body2" className="label purple" about='purple'>*</Typography>
                        </div>
                      </div>
                      {glbsLoading && <Skeleton variant="rect" about="rounded" height={56} />}
                      {glbsError && <Alert severity="error" about='preview'>An error occured while fetching list of glbs please check your internet connection or signin again</Alert>}
                      {glbsData && (
                        <Select
                          onChange={handleChange}
                          name="associatedGlb"
                          value={values.associatedGlb} >
                          {glbsData.users_glbs.map((item, index) => {
                            return <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                          })}
                        </Select>
                      )}
                      {errors.associatedGlb && touched.associatedGlb ? (<Typography variant="body2" className="label" color='red'>{errors.associatedGlb}</Typography>) : null}
                    </>
                    <>
                      <div className="labelBox">
                        <Typography variant="body2" className="label">Product Rarity </Typography>
                        <div className="opacity">
                          <Typography variant="body2" className="label" color='secondary'>
                            (Specify the rarity of your HDRI to be used when rendering)
                          </Typography>
                          <Typography variant="body2" className="label purple" about='purple'>*</Typography>
                        </div>
                      </div>
                      {rarityLoading && <Skeleton variant="rect" about="rounded" height={56} />}
                      {rarityError && <Alert severity="error" about='preview'>An error occured while fetching list of rarity please check your internet connection or signin again</Alert>}
                      {rarityData && (
                        <Select
                          onChange={handleChange}
                          name="productRarity"
                          value={values.productRarity} >
                          {rarityData.rarity.map((rarity, index) => {
                            return <MenuItem key={index} value={rarity.id}>{rarity.title}</MenuItem>
                          })}
                        </Select>
                      )}
                      {errors.productRarity && touched.productRarity ? (<Typography variant="body2" className="label" color='red'>{errors.productRarity}</Typography>) : null}
                    </>
                    <FileUploadComponent {...imageFileProps} />
                    <div className="buttons">
                      <button type="submit" hidden ref={submitBtnRef}></button>
                      <div></div>
                      <Button
                        type="submit"
                        className="btn final"
                        variant="contained"
                        color="primary"
                        value={true}
                        onClick={(e) => {
                          handleSubmitBtn(e, setFieldValue, true);
                        }}
                        disabled={Boolean(loadingState)}
                      >
                        Add Hdri To Glb
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}
