import React from "react";
import styles from "./ProductDescription.module.scss";

export default function ProductDescription() {
  const ProductDescriptionBody = [
    {
      title: "Features",
      class: "features",
      bodyContent:
        "High-quality digital art pieces Each piece is unique and one-of-a-kind Limited supply of only 100 pieces available Can be easily transferred and traded on blockchain networks Comes with a certificate of authenticity",
    },
    {
      title: "Benefits",
      class: "benefits",
      bodyContent:
        "Owning an NFT from the CryptoArt Collection means owning a piece of digital art history With only 100 pieces available, the collection is highly exclusive and coveted NFTs can be easily traded on blockchain networks, making them a valuable investment Each NFT comes with a certificate of authenticity, guaranteeing its uniqueness and value ",
    },
    {
      title: "Technical Specifications",
      class: "specifications",
      bodyContent:
        "NFT Type: ERC-721 Blockchain Network: Ethereum Total Supply: 100 Artwork Format: High-resolution JPEG or PNG Size: 1920 x 1080 pixels ",
    },
  ];

  return (
    <div id={styles.productDescription}>
      {ProductDescriptionBody.map((item) => (
        <div className={`item ${item.class}`} key={item.title}>
          <div className="bottom-title">{item.title}</div>
          <div className="bottom-body">{item.bodyContent}</div>
        </div>
      ))}
    </div>
  );
}
