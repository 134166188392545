import { gql } from "@apollo/client";

export const SINGLE_GLB_INFO = gql`
query SINGLE_GLB_INFO($id:uuid!) {
  users_glbs_by_pk(id: $id){
    SKU
    file_key
    glb_file_url
    name
    id
    placeholder_image_url
    placeholder_key
    private
    glb_category {
      id
    }
  }
}
`