import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./sass/fonts.scss"

import store from './redux/store'
import { Provider } from 'react-redux'

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useLocation } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

const link = from([
  new HttpLink({
    uri: `${process.env.REACT_APP_HASURA_URL}`,
    credentials: 'include',
  }),
]);
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  const includeToken = () => {
    if (token) {
      return { authorization: `Bearer ${token}` }
    } else {
      return null
    }
  }
  return {
    headers: {
      ...headers,
      ...includeToken()
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
