import React from "react";
import MainNavBar from "../Home/components/MainNavBar/MainNavBar";
import styles from "./ListOfProducts.module.scss";
import Products from './components/Products'
import UnAutheticatedGraphql from "../../providers/UnAutheticatedGraphql";

export default function ListOfProducts() {
  return (
    <UnAutheticatedGraphql>
      <div id={styles.listOfProducts}>
        <MainNavBar />
        <Products />
      </div>
    </UnAutheticatedGraphql>
  );
}
