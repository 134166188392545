import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./MainNavBar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setHomeGlbSearch } from "../../../../redux/mainPageSlice";

export default function MainNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const dispatch = useDispatch()
  const homeGlbSearch = useSelector(state => state.mainPageSliceReducer.homeGlbSearch)

  const navLinks = [
    {
      linkName: "Home",
      id: 1,
      path: "/:id",
    },
    {
      linkName: "List of products",
      id: 2,
      path: "/listOfProducts",
    },
    {
      linkName: "Edit product",
      id: 3,
      path: "/editProduct",
    },
    {
      linkName: "User edits",
      id: 4,
      path: "/userEdits",
    },
    {
      linkName: "About us",
      id: 5,
      path: "/aboutUs",
    },
  ];
  const HandleClickedNav = (path) => {
    navigate(path);
    setActive(path);
  };
  return (
    <div id={styles.mainNavBar}>
      <div className="imgeLogo">
        <img src="/images/logo.svg" alt="imageLogo" className="logoIcon" />
      </div>
      <div className="navlinks">
        {navLinks.map((link) => (
          <div
            className={active === link.path ? "link linkActive" : "link"}
            key={link.id}
            onClick={() => HandleClickedNav(link.path)}
          >
            <span className="linkTag">{link.linkName}</span>
          </div>
        ))}
      </div>
      <div className="rightNavoptions">
        <form className="searchForm" autoComplete="off">
          <input
            type="text"
            placeholder="Search product"
            className="searchInput"
            value={homeGlbSearch}
            onChange={(event) => dispatch(setHomeGlbSearch(event.target.value))}
          />
          <img
            src="/images/searchWhite.svg"
            alt="search Icon"
            className="searchIcon"
            onClick={() => navigate("/listOfProducts")}
          />
        </form>
        <img src="/images/menu.svg" alt="menu" className="menu" />
      </div>
    </div>
  );
}
