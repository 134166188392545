import React from 'react'
import styles from "./LHeader.module.scss"

export default function LHeader() {
    const allLinks = [
        {
            linkText: "Tools",
            link: "/"
        },
        {
            linkText: "Assets",
            link: "/"
        },
        {
            linkText: "Products",
            link: "/"
        },
        {
            linkText: "Sdk",
            link: "/"
        },
        {
            linkText: "API",
            link: "/"
        },
        {
            linkText: "TALK TO US",
            link: "/"
        }
    ]
    return (
        <div className="flex" id={styles.LHeader}>
            <div className="l-logo">
                <img src="/images/logo.svg" alt="Skinz logo" />
            </div>
            <div className="links">
                {allLinks.map((link, index) => {
                    return <SingleLink key={index} {...link} />
                })}
            </div>
        </div>
    )
}

const SingleLink = ({ linkText, link }) => {
    return (<>
        <a className="single-link-item" href={link} >
            {linkText}
        </a>
    </>)
}
