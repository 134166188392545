import { gql } from "@apollo/client";

export const NUMBER_OF_LIKES = gql`
subscription NUMBER_OF_LIKES($likedItem:String!) {
  user_likes_aggregate(where: {liked_item: {_eq: $likedItem}}) {
    aggregate {
      count
    }
  }
}
`

export const NUMBER_OF_LIKES_QUERY = gql`
query NUMBER_OF_LIKES_QUERY($likedItem:String!) {
  user_likes_aggregate(where: {liked_item: {_eq: $likedItem}}) {
    aggregate {
      count
    }
  }
}
`