import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";

export const detailsSlice = createSlice({
  name: "imageDetails",
  initialState: {
    details: "",
    cardPopUp: "",
  },
  reducers: {
    setDetails: (state, action) => {
      return produce(state, (draftState) => {
        draftState.details = action.payload;
      });
    },
    setPopUpContent: (state, action) => {
      return produce(state, (draftState) => {
        draftState.cardPopUp = action.payload;
      });
    },
  },
});

export const { setDetails, setPopUpContent } = detailsSlice.actions;

export default detailsSlice.reducer;
