import React from "react";
import LoginOrSignUpPopUp from "../pages/Home/components/StableDiffusion/components/loginOrSignUpPopUp/loginOrSignUpPopUp";

export default function PublicLayout({ children }) {
  return (
    <>
      <LoginOrSignUpPopUp />
      {children}
    </>
  );
}
