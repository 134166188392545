import axios from "axios"

export const unAuthetticatedAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND
})

export const authetictedAxios = () => {
    const token = localStorage.getItem("token")
    return axios.create({
        baseURL: process.env.REACT_APP_BACKEND,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

export const apiRoutes = {
    signup: "/auth/signup",
    comfirmEmail: (token) => `/auth/comfirm/${token}`,
    login: "/auth/login",
    fileUpload: "/file-upload",
    deleteFile: "/file-upload/delete",
    stableDiffusion: "/stable-diffusion",
    stableDiffusionAdmin: "/stable-diffusion/admin",
    likes: "likes",
    doesUsernameExist:"/manage-user-profile/user-exist",
    userPublicProfile: "/user-public-profile"
}

export const users = {
    client: "client"
}