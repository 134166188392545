import React from 'react'
import { useParams } from 'react-router-dom'
import { useComfirm } from './useConfirmHook.hook'
import SuccessSubmition from './components/SuccessSubmitionPage/SuccessSubmition'
import Page404 from './components/404Page/404Page'
import CircularProgressComponent from '../../components/circularProgress/CircularProgress.component'

export default function ComfirmEmail() {
    const { token } = useParams()
    const { loading, data, error } = useComfirm(token)
    return (
        <>
            {loading && <CircularProgressComponent />}
            {data && <SuccessSubmition />}
            {error && <Page404 />}
        </>
    )
}
