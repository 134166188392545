import { Alert, Button, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import EmptyFilter from "../../components/emptyFilter/emptyFilter";
import styles from "./ManageMods.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { useNavigate } from "react-router-dom";
import { DELETE_USER_LIKE, LIKED_ITEM_BY_USER, LIST_OF_USER_LIKES } from "../../graphql/listOfUserLikes.query";

export default function ManageLikes() {
  const { data: listOfGlbs, refetch } = useQuery(LIST_OF_USER_LIKES(), { fetchPolicy: "no-cache" });


  return (
    <DashboardLayout>
      <div className={styles.manageGlb}>
        <div className="upload">
          <div className="top_content">
            <div className="title">Liked Products</div>
            <div className="sub_title">Products that you liked previously </div>
          </div>
          <div className="uploaded_glb">
            <div className="uploaded_glb_top">
              <Typography variant="h5" className="label">
                Liked Products
              </Typography>
            </div>
            {listOfGlbs && listOfGlbs.user_likes.length === 0 && (
              <EmptyFilter
                hideReset={true}
                title="No Matching liked items Found"
                desc="Currently, it appears you haven't 'liked' any products. To begin, go to our 'Product List' to like your first item. Any products you like will then be visible here."
              />
            )}
            {listOfGlbs && listOfGlbs.user_likes.length !== 0 && (
              <div className="uploaded_glb_body">
                <div className="title_box">
                  <Typography variant="body2" className="child">
                    #
                  </Typography>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      Products
                    </Typography>
                  </div>
                  <div className="sort_box child">
                    <Typography variant="body2" className="child">
                      SKU
                    </Typography>
                  </div>
                  <Typography variant="body2" className="child">
                    View Product
                  </Typography>
                  <Typography variant="body2" className="child">
                    Remove liked Product
                  </Typography>
                </div>
                <div className="content_box">
                  {listOfGlbs &&
                    listOfGlbs.user_likes.map((item, id) => {
                      return <SingleLikedItem id={item.liked_item} key={id} number={id} refetch={refetch} />;
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
function SingleLikedItem({ id, number, refetch }) {
  const { data } = useQuery(LIKED_ITEM_BY_USER, { fetchPolicy: "no-cache", variables: { id: id } });

  const [deleteLike, { error: errorDeletingUserLike }] = useMutation(DELETE_USER_LIKE);
  const handleDeleteUserLike = async () => {
    await deleteLike({ variables: { id: id } });
    refetch();
  };

  const [close, setClose] = useState(true);

  return (
    <>
      {errorDeletingUserLike && (
        <Snackbar open={close} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => setClose(false)}>
          <Alert severity="error" sx={{ width: "100%" }}>
            Error deleting user like please check your network and try again
          </Alert>
        </Snackbar>
      )}
      {data && (
        <div key={id} className="row">
          <Typography variant="body2" className="child">
            {number + 1}
          </Typography>
          <div className="glb">
            <img src={data.users_glbs_by_pk.placeholder_image_url} alt="imageLogo" className="image" />
            <Typography variant="body2" className="child">
              {data.users_glbs_by_pk.name}
            </Typography>
          </div>
          <Typography variant="body2" className="child">
            {data.users_glbs_by_pk.SKU}
          </Typography>
          <a href={`/${data.users_glbs_by_pk.id}`} target="_blank" rel="noopener noreferrer">
            <Button variant="outlined" className="preview child">
              View Product
            </Button>
          </a>
          <Button variant="outlined" className="delete child" onClick={handleDeleteUserLike}>
            Remove
          </Button>
        </div>
      )}
    </>
  );
}
