import { gql } from "@apollo/client";

export const LIST_OF_USER_LIKES = () => {
    return gql`
query LIST_OF_USER_LIKES {
  user_likes{
    liked_item
  }
}
`
};


export const LIKED_ITEM_BY_USER = gql`
query GET_USER_PUBLIC_PROFILE($id: uuid!) {
        users_glbs_by_pk(id: $id){
            SKU
            placeholder_image_url
            name
            id
        }
    }

`

export const DELETE_USER_LIKE = gql`
mutation DELETE_USER_LIKE($id:String!) {
  delete_user_likes(where: {liked_item: {_eq: $id}}){
    affected_rows
  }
}
`