import { useState, useEffect } from 'react';

export function useDivResize(el) {
    const div = el.getBoundingClientRect();
    const [size, setSize] = useState({
        width: div.width,
        height: div.height,
    });
    return size;
}
