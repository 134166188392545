import { gql } from "@apollo/client";

export const EDIT_USER_GLB_MOD = gql`
mutation EDIT_USER_GLB_MOD(
    $fileKey:String!
    $glb_file_url:String!
    $moded_glb:uuid!
    $name:String!
    $placeholder_image_url:String!
    $image_key:String!
    $id:Int!
) {
 delete_user_moded_glb_by_pk(id: $id) {
    id
  }
  insert_user_moded_glb_one(object: {file_key: $fileKey, glb_file_url: $glb_file_url, moded_glb: $moded_glb, name: $name, placeholder_image_url: $placeholder_image_url,image_key:$image_key}) {
    file_key
    glb_file_url
  }
}
`