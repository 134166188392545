import { Typography } from "@mui/material";
import React from "react";
import styles from "./Page404.module.scss";

export default function Page404() {
  return (
    <div id={styles.page404}>
      <div className="imageBox">
        <img src="/images/logoBlack.svg" alt="logo" />
      </div>
      <div className="body">
        <div className="imgTop"></div>
        <Typography variant="h3" className="title" sx={{ textAlign: "center"}}>User matching the token not found</Typography>
        <Typography variant="body2" color="secondary" sx={{ textAlign: "center"}} className="centered">
          Sorry, the token you have provided is invalid. Please check that you
          have entered the correct token, or request a new one. If you continue
          to experience issues, please contact our support team for assistance.
        </Typography>
      </div>
    </div>
  );
}
